import { Button, Dropdown, Layout, Space, Badge, Menu, Breadcrumb, Divider, Skeleton, Tooltip, Typography, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { getEnvironmentService, getNotificationService, getProjectService, isAIServiceEnabled } from "../../backend";

import Logo from "../../assets/logo-white.svg";
import Avatar from "antd/lib/avatar/avatar";
import { useAuth } from "../../contexts/Auth";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { currentProjectState, projectsState } from "../../recoil/project";
import Icon, {
    UserOutlined,
    PlusCircleOutlined,
    DownOutlined,
    BellFilled,
    AppstoreOutlined,
    MonitorOutlined,
    DollarOutlined,
    DeploymentUnitOutlined,
    SettingOutlined,
    NotificationFilled,
    FileTextOutlined,
    NodeIndexOutlined,
    ProductOutlined,
    CrownOutlined,
    WarningFilled,
    RobotOutlined
} from "@ant-design/icons";
import { useCurrentProject } from "../../contexts/Project";
import { handleSetMixpanelUserGroups, handleTrackLogoutEvent } from "../../backend/tracking";
import ChangelogDrawer from "./ChangelogDrawer";
import { trackNotificationsClicked, trackWhatsNewClicked } from "../../backend/tracking/user-settings";
import NotificationsDrawer from "./NotificationsDrawer";
import { newNotificationsState } from "../../recoil/notification";
import { UserNotificationsNotifications } from "@microtica/ms-notification-sdk";
import { ItemType } from "antd/es/menu/interface";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import AlarmSvg from "../../assets/alarm-icon";

const { Header, Content, Sider } = Layout;

const PageLayout = () => {
    const { Text } = Typography;
    const badgeColor = "#1890ff";
    const { projectId, envId } = useParams() as { projectId: string; envId: string; componentId: string; namespace: string; appName: string; };
    const { pathname } = useLocation()
    const navigate = useNavigate();
    const { profile } = useAuth();
    const { updateCurrentProject, paymentMethodRequired, trialDaysLeft, isProjectActive } = useCurrentProject();
    // recoil project
    const [projects, setProjects] = useRecoilState(projectsState);
    const resetProjectsState = useResetRecoilState(projectsState);
    const currentProject = useRecoilValue(currentProjectState);
    const [envName, setEnvName] = useState<string>();
    // recoil notification
    const setNewUserNotifications = useSetRecoilState(newNotificationsState);
    // useState
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [showChangelog, setShowChangelog] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showAlarmNotifications, setShowAlarmNotifications] = useState(false);
    const [userNotifications, setUserNotifications] = useState([] as UserNotificationsNotifications[]);

    const [selectedMenuItem, setSelectedMenuItem] = useState<string>("environments");
    const [hideAlarmsMenuItem, setHideAlarmsMenuItem] = useState(false);

    const loadData = async () => {
        setLoadingProjects(true);
        try {
            const { data: { projects } } = await getProjectService().listProjects();
            const projectsWithDetails = await Promise.all(
                (projects || [])
                    .map(async (project) => {
                        const { data } = await getProjectService().getProject(project.id);
                        return data;
                    })
            )
            setProjects(projectsWithDetails);
            handleSetMixpanelUserGroups(projects.map(item => item.id));
        } catch (error) {
        } finally {
            setLoadingProjects(false);
        }
    }

    useEffect(() => {
        loadData();
        fetchNotifications();

        const notificationsInterval = setInterval(async () => {
            await fetchNotifications();
        }, 30000);

        return () => {
            clearInterval(notificationsInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async () => {
        const { data: { name } } = await getEnvironmentService().getStage(envId, projectId);
        setEnvName(name);
    }

    useEffect(() => {
        if (envId) {
            load();
        }
    }, [envId]);

    useEffect(() => {
        if (/^\/projects\/([^\/]+)\/environments(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("environments");
        } else if (/^\/projects\/([^\/]+)\/templates(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("templates");
        } else if (/^\/projects\/([^\/]+)\/settings(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("settings");
        } else if (/^\/projects\/([^\/]+)\/integrations(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("integrations");
        } else if (/^\/projects\/([^\/]+)\/pipelines(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("pipelines");
        } else if (/^\/projects\/([^\/]+)\/cost-explorer(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("cost-explorer");
        } else if (/^\/projects\/([^\/].+)\/alarm(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("alarms");
        } else if (/^\/projects\/([^\/].+)\/alarms(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("alarms");
        } else if (/^\/projects\/([^\/].+)\/monitoring(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("monitoring");
        } else if (/^\/projects\/([^\/].+)\/chat(?:\/.*)?$/.test(pathname)) {
            setSelectedMenuItem("chat");
        } else {
            setSelectedMenuItem("");
        }
    }, [pathname]);

    const handleSelectedProject = async (projectId: string) => {
        await updateCurrentProject(projectId);

        (window as Window).location = `/projects/${projectId}/environments`
    }

    const handleLogout = () => {
        localStorage.removeItem('idToken');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('profile');

        resetProjectsState();

        handleTrackLogoutEvent();

        navigate("/auth/login");
    }

    const handleOpenChangelog = () => {
        setShowChangelog(true);
        trackWhatsNewClicked();
    }

    const handleOpenNotifications = () => {
        setShowNotifications(true);
        trackNotificationsClicked();
    }

    const handleOpenAlarmNotifications = () => {
        setShowAlarmNotifications(true);
    }

    const fetchNotifications = async () => {
        const { data: { notifications } } = await getNotificationService().getAllUserNotifications();

        // update recoil state only if there are new notifications
        setUserNotifications(oldNotifications => {
            if ((oldNotifications.length === 0 && notifications.length > 0) ||
                (notifications[0]?.id !== oldNotifications[0]?.id || notifications.filter(n => n.isRead).length !== oldNotifications.filter(o => o.isRead).length)) {
                const oldNotificationsIds = oldNotifications.map(oldNotification => oldNotification.id);
                const newNotifications = notifications.filter(notification => !oldNotificationsIds.includes(notification.id));
                setNewUserNotifications(newNotifications);
                return notifications;
            } else {
                return oldNotifications;
            }
        })
    }

    const MainMenu = useMemo(() => (
        <Menu
            theme="dark"
            selectedKeys={[selectedMenuItem]}
            defaultSelectedKeys={['environments']}
            inlineIndent={12}
            mode={'inline'}
            items={[
                {
                    key: "environments",
                    label: <Link to={`/projects/${projectId}/environments`}>
                        Environments
                    </Link>,
                    icon: <AppstoreOutlined />
                },
                {
                    key: "pipelines",
                    label: <Link to={`/projects/${projectId}/pipelines`}>
                        Pipelines
                    </Link>,
                    icon: <NodeIndexOutlined />
                },
                {
                    key: "monitoring",
                    label: <div style={{ display: "flex", width: "100%" }}>
                        <Link to={`/projects/${projectId}/monitoring`}
                            style={{ color: "rgba(255, 255, 255, 0.65)", width: "70%" }}
                        >
                            Monitoring
                        </Link>
                        <div
                            className="monitoring-icon-wrapper"
                            onClick={() => setHideAlarmsMenuItem(!hideAlarmsMenuItem)}
                        >
                            <i className={`monitoring-menu-icon-${hideAlarmsMenuItem ? "down" : "up"}`}></i>
                        </div>
                    </div>,
                    icon: <MonitorOutlined />,
                },
                {
                    key: "alarms",
                    label: <Link to={`/projects/${projectId}/monitoring/alarms`}>
                        Alarms
                    </Link>,
                    style: { display: hideAlarmsMenuItem ? "none" : "block" },
                    className: "nested-menu-item",
                    icon: <Icon component={AlarmSvg} />
                },
                {
                    key: "cost-explorer",
                    label: <Link to={`/projects/${projectId}/cost-explorer`}>
                        Cost Explorer
                    </Link>,
                    icon: <DollarOutlined />
                },
                {
                    key: "templates",
                    label: <Link to={`/projects/${projectId}/templates`}>
                        Templates
                    </Link>,
                    icon: <ProductOutlined />
                },
                {
                    key: "integrations",
                    label: <Link to={`/projects/${projectId}/integrations`}>
                        Integrations
                    </Link>,
                    icon: <DeploymentUnitOutlined />
                },
                {
                    key: "settings",
                    label: <Link to={`/projects/${projectId}/settings`}>
                        Settings
                    </Link>,
                    icon: <SettingOutlined />
                }
            ].concat(
                isAIServiceEnabled(projectId) ?
                    [{
                        key: "chat",
                        label: <Link to={`/projects/${projectId}/chat`}>
                            AI assistant
                        </Link>,
                        icon: <RobotOutlined />
                    }] : []
            )}
        />
    ), [hideAlarmsMenuItem, selectedMenuItem]);

    const BottomMenu = () => (
        <Menu
            theme="dark"
            selectedKeys={[selectedMenuItem]}
            defaultSelectedKeys={['environments']}
            items={[
                {
                    key: "docs",
                    label: <a href="https://docs.microtica.com" target="_blank" rel="noreferrer">
                        Documentation
                    </a>,
                    icon: <FileTextOutlined />
                }
            ]} />
    )

    const ProjectsDropdown = () => (
        <div className="projects-button">
            <Dropdown
                trigger={["click"]}
                menu={{
                    items: projects
                        .map(project => ({
                            key: project.id,
                            onClick: () => handleSelectedProject(project.id),
                            label: <div>
                                <div>
                                    <Text ellipsis={{ tooltip: project.name }}>{project.name}</Text>
                                </div>
                                <div>
                                    <Tag color="blue">
                                        <Space>
                                            {project.paymentPlan?.id !== "FREE" ? <CrownOutlined /> : null}
                                            {project.paymentPlan?.name}
                                        </Space>
                                    </Tag>
                                </div>
                            </div>,

                        }) as ItemType)
                        .concat([
                            {
                                type: "divider"
                            },
                            {
                                key: "new-project",
                                label: <Link to="/pricing/new">
                                    <PlusCircleOutlined /> Create Project
                                </Link>
                            }
                        ])
                }}
            >
                <Button type="default">
                    <div className="flex-justify-space-between" style={{ width: "100%" }}>
                        <Text ellipsis={{ tooltip: currentProject?.name }} style={{ color: "inherit" }}>
                            {currentProject?.name}
                        </Text>
                        <DownOutlined />
                    </div>
                </Button>
            </Dropdown>
        </div>
    )

    const HeaderBreadcrumb = () => {
        const items: BreadcrumbItemType[] = [];
        if (projectId) {
            items.push({
                key: "projectName",
                title: <b>{currentProject.name}</b>,
                onClick: () => navigate(`/projects/${currentProject.id}/environments`)
            });
        }
        if (envId) {
            items.push({
                key: "envName",
                title: !envName ? <Skeleton.Input size="small" /> : <b>{envName}</b>,
                onClick: () => navigate(`/projects/${currentProject.id}/environments/${envId}`)
            });
        }
        if (pathname.split("/").includes("alarms")) {
            items.push({
                key: "alarms",
                title: <b>Alarms</b>
            });
        }
        return <div className="flex-align-center">
            <Button style={{ marginRight: "10px" }} onClick={() => navigate("/projects")}>
                <Space>
                    <b>See All Projects</b>
                </Space>
            </Button>
            <Breadcrumb items={items} />
        </div>;
    }

    const HeaderActions = useMemo(() => {
        return (<div>
            <Space size="middle">
                <Badge
                    style={{ backgroundColor: badgeColor }}
                    count={userNotifications.filter(notification => !notification.isRead && notification.entityType !== "MONITORING").length}
                    size="small"
                    offset={[-10, -5]}>
                    <Button
                        type="text"
                        icon={<BellFilled style={{ fontSize: "22px !important" }} />}
                        className="ant-btn-ghost-inverse"
                        onClick={handleOpenNotifications}
                    >
                        <div className="button-text">
                            Updates
                        </div>
                    </Button>
                </Badge>
                <Badge
                    style={{ backgroundColor: "red" }}
                    count={userNotifications.filter(notification => !notification.isRead && notification.entityType === "MONITORING").length}
                    size="small"
                    offset={[-10, -5]}>
                    <Button
                        type="text"
                        icon={<WarningFilled style={{ fontSize: "18px" }} />}
                        className="ant-btn-ghost-inverse"
                        onClick={handleOpenAlarmNotifications}
                    >
                        <div className="button-text">
                            Incidents
                        </div>
                    </Button>
                </Badge>
                <Badge
                    style={{ backgroundColor: badgeColor }}
                    size="small"
                    offset={[-10, -5]}
                >
                    <Button
                        type="text"
                        icon={<NotificationFilled />}
                        className="ant-btn-ghost-inverse"
                        onClick={handleOpenChangelog}
                    >
                        <div className="button-text">
                            What's new
                        </div>
                    </Button>
                </Badge>
                <Dropdown
                    placement="bottomRight"
                    menu={{
                        items: [{
                            key: "profile-email",
                            label: profile?.email,
                            disabled: true
                        },
                        {
                            key: "logout",
                            label: "Logout",
                            onClick: handleLogout
                        }]
                    }}
                    trigger={['click']}
                >
                    <Avatar icon={<UserOutlined />} style={{ cursor: "pointer" }} />
                </Dropdown>
            </Space>
        </div>
        )
    }, [userNotifications]);

    const ProjectInfo = () => (
        <>
            {
                isProjectActive &&
                paymentMethodRequired &&
                trialDaysLeft && trialDaysLeft > 0 &&
                <div className="info-box">
                    <Space direction="vertical">
                        <Tooltip title={`You still don't have a payment method added. Add a payment method to be able to use the project after the trial ends.`}>
                            <div style={{ color: "white" }}>
                                Free Trial ends in {trialDaysLeft} days!
                            </div>
                        </Tooltip>
                        <Link to={`projects/${projectId}/settings/billing`}>Add Payment Method</Link>
                    </Space>
                </div>
            }
            {
                (!!currentProject && !isProjectActive) ?
                    <div className="info-box">
                        <Space direction="vertical">
                            <Tooltip title="Your project is currently in an inactive state. Upgrade the project plan to continue using the project.">
                                <div style={{ color: "white" }}>
                                    Your Free Trial ended.
                                </div>
                            </Tooltip>
                            <Link to="pricing/upgrade"> Upgrade now!</Link>
                        </Space>
                    </div>
                    : null
            }
        </>
    )

    return (
        <Layout hasSider>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0
                }}
                collapsedWidth="65px"
            >
                <div
                    className="flex-justify-space-between"
                    style={{ flexDirection: "column", height: "100%" }}
                >
                    <div className="main-menu">
                        <div style={{ margin: "16px 5px" }}>
                            <Link to="/projects">
                                <img src={Logo} height="40px" alt="Microtica" />
                            </Link>
                        </div>

                        <ProjectsDropdown />

                        <Divider />

                        {MainMenu}

                        <Divider />

                        <ProjectInfo />

                    </div>
                    <div>
                        <BottomMenu />
                    </div>
                </div>
            </Sider>
            <Layout style={{ marginLeft: 200 }}>
                <Header className="header flex-align-center flex-justify-space-between" >
                    <HeaderBreadcrumb />
                    {HeaderActions}
                </Header>
                <Content style={{ overflow: 'initial' }}>
                    <Outlet />
                </Content>
            </Layout>
            <ChangelogDrawer visible={showChangelog} onClose={() => setShowChangelog(false)} />
            {
                (showNotifications || showAlarmNotifications) &&
                <NotificationsDrawer
                    projects={projects}
                    notifications={userNotifications.filter(notification => showAlarmNotifications ? notification.entityType === "MONITORING" : notification.entityType !== "MONITORING")}
                    visible={showNotifications || showAlarmNotifications}
                    type={showAlarmNotifications ? "Incidents" : "Updates"}
                    onClose={() => {
                        setShowNotifications(false);
                        setShowAlarmNotifications(false);
                        fetchNotifications();
                    }}
                />
            }
        </Layout>
    );
}

export default PageLayout;