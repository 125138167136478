import { CheckCircleOutlined, CrownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Col, Collapse, Divider, Dropdown, Form, Modal, notification, Radio, Row, Space, Typography } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { getEnvironmentService, getProjectService } from "../../backend";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import AppSettingsScalingExplanation from "../../components/explanations/AppSettingsScalingExplanation";
import { ComponentSchemaInputProp, ComponentSchemaInputProperties, Dictionary, IComponentDetails, PricingPlan } from "../../types";

import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { PricingPlanNames } from "../../enums/enums";
import { useCurrentProject } from "../../contexts/Project";
import Title from "antd/lib/typography/Title";
import ResourceScalingScreenshot from "../../assets/resource-scaling.png"
import { getDetailedPaymentPlan } from "../../utils/payment-plans";
import ComponentConfigFormItems from "../../components/ComponentConfigFormItems";
const { Text } = Typography;


const ResourceSettingsScaling = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { projectId, envId } = useParams();
    const { component } = useOutletContext<{ component: IComponentDetails }>();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loading, setLoading] = useState(false);
    const { paymentPlan: userPaymentPlan, id, freeTrials } = useRecoilValue(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();
    const [nextPaymentPlan, setNextPaymentPlan] = useState<PricingPlan>()
    const [allocationProps, setAllocationProps] = useState<Dictionary<ComponentSchemaInputProp>>({});
    const [scalingProps, setScalingProps] = useState<Dictionary<ComponentSchemaInputProp>>({});
    const [inputProps] = useState<ComponentSchemaInputProperties>(component.component.schema.properties.inputs.properties);
    const [references] = useState<string[]>((component.configurations || []).filter(val => val.reference).map(val => val.key));
    const [scalingType, setScalingType] = useState<"fixedSize" | "autoscale">("fixedSize");
    const disabledAppScalingProperties = scalingType === "autoscale" && userPaymentPlan?.id === PricingPlanNames.STARTER;

    useEffect(() => {
        setAllocationProps(getPropsInCategory("allocation"));

        const initialValues = Object.entries(inputProps).reduce((acc, [key, value]) => {
            // Get the default value from the schema
            const val = component.configurations.find(c => c.key === key)?.value || value.default;
            const defaultValue = value.type === "array" ?
                (val as string)?.split(",") :
                val;
            acc[key] = defaultValue;
            return acc;
        }, {} as Dictionary<string | string[] | number | undefined>);

        form.setFieldsValue(initialValues);

        const isAutoscalingDefault = !Object
            .entries(inputProps)
            .filter(([, prop]) =>
                (prop.category === "scaling" || prop.category === "autoscaling") && prop.subcategory === "replicas"
            )
            .every(([key], _, replicaProps) => {
                return userPaymentPlan?.id !== PricingPlanNames.FREE &&
                    userPaymentPlan?.id !== PricingPlanNames.STARTER &&
                    initialValues[key] === initialValues[replicaProps[0][0]];
            });

        setScalingType(isAutoscalingDefault ? "autoscale" : "fixedSize");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (scalingType === "autoscale") {
            setScalingProps(getPropsInCategory("autoscaling"));
        } else if (scalingType === "fixedSize") {
            setScalingProps(getPropsInCategory("scaling"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scalingType]);

    useEffect(() => {
        async function loadData() {
            if (userPaymentPlan?.id === PricingPlanNames.FREE) {
                const starterPlan = await getDetailedPaymentPlan(PricingPlanNames.STARTER);
                setNextPaymentPlan(starterPlan);
            } else {
                const advancedPlan = await getDetailedPaymentPlan(PricingPlanNames.ADVANCED);
                setNextPaymentPlan(advancedPlan);
            }
        }
        loadData();
    }, [userPaymentPlan]);

    const handleSave = async () => {
        const values = await form.validateFields() as Dictionary<string>;
        const desiredReplicasPropKey = Object.keys(inputProps).find((key) => {
            return inputProps[key].category === "scaling" && inputProps[key].subcategory === "replicas";
        });
        if (scalingType === "fixedSize") {
            Object.keys(inputProps).forEach((key) => {
                if (inputProps[key].category === "autoscaling" &&
                    inputProps[key].subcategory === "replicas" &&
                    desiredReplicasPropKey
                ) {
                    values[key] = values[desiredReplicasPropKey];
                    form.setFieldValue(key, values[desiredReplicasPropKey]);
                }
            })
        } else if (desiredReplicasPropKey && scalingType === "autoscale") {
            const autoscalingValues = Object.keys(inputProps)
                .filter(key => inputProps[key].category === "autoscaling" && inputProps[key].subcategory === "replicas")
                .map(key => parseInt(values[key]));

            const desiredReplicasValue = Math.min(...autoscalingValues).toString()
            values[desiredReplicasPropKey] = desiredReplicasValue;

            form.setFieldValue(desiredReplicasPropKey, desiredReplicasValue);
        }

        const configurations = Object.keys(values).reduce((acc, key) => {
            const value = values[key];
            const config = acc.find(a => a.key === key);

            if (config) {
                config.value = value.toString();
            } else {
                acc.push({
                    key,
                    value: value.toString(),
                    sensitive: !!inputProps[key].sensitive,
                    reference: references.includes(key)
                });
            }
            return acc;
        }, component.configurations);

        setLoading(true);
        await getEnvironmentService().updateResource(
            envId!,
            component.name,
            projectId!,
            {
                componentVersion: component.version,
                configurations
            }
        );
        setLoading(false);
    }

    const deployResource = async () => {
        await getEnvironmentService().deployStage(
            envId!,
            projectId!,
            {
                partial: true,
                resourceVersionOverrides: {
                    [component.name]: component.version
                }
            }
        );
    }

    const handleSaveAndDeploy = async () => {
        try {
            if (disabledAppScalingProperties) {
                await showSelectPlanConfirm();
            }

            setLoading(true);

            await handleSave();
            await deployResource();

            notification.success({
                message: "Scaling in Progress",
                description: <>
                    It could take a few moments for the changes to take effect.
                    <div>
                        <a href={`/projects/${projectId}/pipelines`}>View in Pipelines</a>
                    </div>
                </>,
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
        } catch (error: any) {
            notification.error({
                message: "Application scaling failed",
                description: error.response.data.message
            });
        } finally {
            setLoading(false);
        }
    }

    const getPropsInCategory = (category: string) => {
        return Object.keys(inputProps).reduce((acc, key) => {
            const prop = inputProps[key];
            if (prop.category === category) {
                acc[key] = prop;
            }
            return acc;
        }, {} as Dictionary<ComponentSchemaInputProp>);
    }

    const isTrialEligible = (planId: string) => {
        return freeTrials && !(freeTrials as Dictionary<boolean>)[planId];
    }

    const handleSelectPlan = async () => {
        try {
            await getProjectService().changeProjectSubscription(
                id,
                {
                    paymentPlanId: nextPaymentPlan!.id,
                    priceId: nextPaymentPlan!.priceId!
                }
            );
            await updateCurrentProject(id);
        } catch (error: any) {
            notification.error({
                message: `Error activating the ${nextPaymentPlan!.name} plan`,
                description: <>
                    {error.response.data.message}&nbsp;
                    <a href={`/projects/${id}/settings/billing`}>Go to Billing</a> to check your settings.
                </>
            })
        }
    }

    const showSelectPlanConfirm = () =>
        new Promise((resolve) => {
            Modal.confirm({
                title: `Activate ${nextPaymentPlan!.name} plan`,
                content:
                    <Space direction='vertical'>
                        <div>
                            You are about to activate the {nextPaymentPlan!.name} plan that will cost {nextPaymentPlan!.price} per month.
                        </div>
                        {
                            isTrialEligible(nextPaymentPlan!.id) ?
                                <div>
                                    <b>7 day free trial</b> will be applied.
                                </div> :
                                <div>
                                    Your credit card will be <b>charged immediately</b> because you've already used your free trial period.
                                </div>
                        }
                    </Space>,
                okText: `Activate ${nextPaymentPlan!.name} plan`,
                centered: true,
                async onOk() {
                    await handleSelectPlan();
                    return resolve(undefined);
                }
            });
        })


    const disabledAppScaling = (<div className="ant-card ant-card-bordered">
        <Row>
            <Col offset={2} span={9} className="flex-align-center">
                <Space direction="vertical" size={24}>
                    <Space size={16} direction="vertical">
                        <Space direction="vertical" size={0}>
                            <Title level={4} style={{ color: "green", fontWeight: "bold", }}>
                                Unlock Advanced Scaling
                            </Title>
                        </Space>
                        <div>
                            <ul className="no-margin" style={{ paddingLeft: 0, listStyleType: "none" }}>
                                <li><CheckCircleOutlined style={{ color: "green" }} /> Scale based on CPU and Memory utilization</li>
                                <li><CheckCircleOutlined style={{ color: "green" }} /> Custom thresholds for auto-scaling</li>
                                <li><CheckCircleOutlined style={{ color: "green" }} /> Fine-tune resource allocation</li>
                            </ul>
                        </div>
                    </Space>
                    <Button
                        type="primary"
                        onClick={() => showSelectPlanConfirm()}>
                        <Space>
                            <CrownOutlined />
                            Unlock Scaling
                        </Space>
                    </Button>
                </Space>
            </Col>
            <Col offset={1} span={12}>
                <img src={ResourceScalingScreenshot} alt="cost-explorer" className="cost-dashboard-screenshot" />
            </Col>
        </Row>
    </div>)

    const appScaling = (
        <Form
            form={form}
            layout="vertical"
            requiredMark={requiredMark}
            onFinish={handleSaveAndDeploy}
            className="full-width"
        >
            <Collapse defaultActiveKey={["instances", "cpu-memory"]}>
                {/* CPU/Memory */}
                <Collapse.Panel key="cpu-memory" header={
                    <>
                        <b>Resource allocation</b>
                        <div className="gray-text">
                            Fine-tune the allocation of CPU and Memory resources for your application to optimize performance.
                        </div>
                    </>
                }>
                    <Row gutter={[24, 12]}>
                        <Col span={24}>
                            <ComponentConfigFormItems
                                formItems={allocationProps}
                                required={component.component.schema.properties.inputs.required || []}
                                setFieldsValue={form.setFieldsValue}
                                getFieldValue={form.getFieldValue}
                                references={[]}
                                handleUpdateReferences={() => { }}
                                filterCategories={["allocation"]}
                            />
                        </Col>
                    </Row>
                </Collapse.Panel>

                {/* replicas/instances */}
                <Collapse.Panel key="instances" header={
                    <>
                        <b>Scaling options</b>
                        <div className="gray-text">
                            Define the auto-scaling parameters to trigger scaling actions when resource utilization exceeds 80%, maintaining instances within a specified range of minimum and maximum replicas.
                        </div>
                    </>
                }>
                    <Row gutter={[24, 12]}>
                        <Col span={24}>
                            <Radio.Group
                                className="full-width"
                                defaultValue={scalingType}
                                value={scalingType}
                                onChange={(e) => { setScalingType(e.target.value); }}
                            >
                                <Row gutter={[24, 24]}>
                                    <Col span={12}>
                                        <Card size="small">
                                            <Radio value="fixedSize">
                                                <div>
                                                    Manual scaling
                                                    <div className="gray-text">Workloads with predictable patterns</div>
                                                </div>
                                            </Radio>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card size="small">
                                            <Radio value="autoscale">
                                                {
                                                    userPaymentPlan?.id === PricingPlanNames.STARTER && <><CrownOutlined style={{ color: "goldenrod" }} />&nbsp;</>
                                                }
                                                Autoscaling
                                                <div className="gray-text">For unpredictable workloads</div>
                                            </Radio>
                                        </Card>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Col>
                    </Row>

                    <br />

                    <Row gutter={[24, 12]}>
                        <Col span={24} style={{ paddingBottom: 10 }}>
                            <Text style={{ fontSize: 15 }} strong>Scaling thresholds</Text>
                        </Col>
                    </Row>
                    <Row gutter={[24, 12]}>
                        <Col span={24}>
                            <ComponentConfigFormItems
                                formItems={scalingProps}
                                required={component.component.schema.properties.inputs.required || []}
                                setFieldsValue={form.setFieldsValue}
                                getFieldValue={form.getFieldValue}
                                references={[]}
                                handleUpdateReferences={() => { }}
                                filterCategories={["scaling", "autoscaling"]}
                            />
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
            <br />

            <Row className="flex-justify-end">
                <Col>
                    <Dropdown.Button
                        trigger={["click"]}
                        htmlType="submit"
                        loading={loading}
                        disabled={userPaymentPlan?.id === "FREE"}
                        menu={{
                            items: [{
                                key: "save",
                                label: "Save",
                                onClick: () => handleSave()
                            }]
                        }}
                    >
                        Save and Deploy
                    </Dropdown.Button>
                </Col>
            </Row>
        </Form >
    )

    return (
        <Card bordered>
            <Card.Meta
                title={
                    <>
                        <span>
                            Resource and Scaling Configuration
                        </span>
                        <ExplanationButton
                            content={<AppSettingsScalingExplanation />}
                        />
                    </>
                }
                description="Fine-tune resource allocation for CPU and Memory while setting up automatic scaling parameters based on utilization thresholds."
            />
            <Divider />
            {
                userPaymentPlan?.id === PricingPlanNames.FREE ?
                    disabledAppScaling :
                    appScaling
            }
        </Card>
    );
}

export default ResourceSettingsScaling;