import { RobotOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { getAIService } from "../../backend";

interface AIAssistantModalProps {
    projectId: string;
    prompt: string;
    open?: boolean;
    emptyPlaceholder?: string;
    onCancel?: () => void;
}

const AIAssistantModal = ({
    projectId,
    prompt,
    emptyPlaceholder = "Analyzing the issue... Please wait.",
    open,
    onCancel,
}: AIAssistantModalProps) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [solution, setSolution] = useState("");

    useEffect(() => {
        const load = async () => {
            setLoading(true);

            try {
                const { data: { solution } } = await getAIService().getSolutionForCfnError(projectId, {
                    error: prompt
                });

                setSolution(solution);
            } catch (error: any) {
                console.log("setError: ", error);
                setError(error?.response?.data?.message);
            } finally {
                setLoading(false);
            }
        }

        load();
    }, []);

    return <Modal
        title="AI Assistant"
        centered
        closable
        open={open}
        footer={null}
        onCancel={onCancel}
        className="ant-scollable-modal"
        width={800}
        style={{ overflow: "scroll" }}
        height={"90vh"}
    >
        <br />
        {
            loading ?
                <div className="text-center">
                    <p>
                        <RobotOutlined style={{ fontSize: 40 }} />
                    </p>
                    <p>
                        {emptyPlaceholder}
                    </p>
                </div> :
                error ?
                    <div className="text-center">
                        <p>
                            <RobotOutlined style={{ fontSize: 40 }} />
                        </p>
                        <p>
                            <span style={{ color: "red" }}>Error occurred: </span>{error}
                        </p>
                    </div> :
                    <div>
                        <MarkdownPreview
                            source={solution}
                            wrapperElement={{
                                "data-color-mode": "light"
                            }}
                            style={{ padding: "10px" }}
                        />
                    </div>
        }
    </Modal>

}

export default AIAssistantModal;