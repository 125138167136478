import { CheckCircleTwoTone, DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Dropdown, Empty, Space, Table, Tooltip, notification } from "antd";
import { useEffect, useState } from "react";
import GitCommitLink from "../../components/GitCommitLink";
import moment from "moment";
import { getEnvironmentService, getPipelinesService } from "../../backend";
import { useNavigate, useParams } from "react-router";
import BuildStatusTag from "../../components/BuildStatusTag";
import { Component, IEnvDetails, PipelineItem } from "../../types";
import BuildDetailsDrawer from "../../components/BuildDetailsDrawer";
import { getPipelines } from "../../utils/deployments";
import DeploymentStatusTag from "../../components/DeploymentStatusTag";
import { newUpdateNotificationsState } from "../../recoil/notification";
import { useRecoilValue } from "recoil";
import ComponentDeployDetailsDrawer from "../../components/ComponentDeployDetailsDrawer";
import AppDeployDetailsDrawer from "../../components/AppDeployDetailsDrawer";

interface AppPipelinesProps {
    env: IEnvDetails;
    component: Component;
}

export default function AppPipelines({
    env,
    component
}: AppPipelinesProps) {
    const navigate = useNavigate();
    const updates = useRecoilValue(newUpdateNotificationsState);
    const [branches, setBranches] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [pipelineId, setPipelineId] = useState<string>();
    const [pipelines, setPipelines] = useState<PipelineItem[]>([]);
    const { projectId, envId, componentId, appName } = useParams() as { projectId: string; envId: string; componentId: string; appName: string };
    const [showPipelineDetails, setShowPipelineDetails] = useState(false);
    const [selectedPipeline, setSelectedPipeline] = useState<PipelineItem>();
    const [activeDeployment, setActiveDeployment] = useState<string>();

    const disableManualBuild = false// !!buildMinutesUsedPercentage && buildMinutesUsedPercentage >= 100;

    useEffect(() => {
        setLoading(true);
        setPipelines([]);
        loadData();
    }, [componentId, appName]);


    useEffect(() => {
        if (updates.some(update => update.projectId === projectId)) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updates]);

    const getCurrentDeployment = async () => {
        if (appName) {
            const app = component.apps?.find(a => a.name === appName)!;
            return {
                isComponentPublic: false,
                version: app.deployment?.version,
                pipelineId: app.deployment?.pipelineId!
            };
        } else {
            const { data: { pipelineId } } = await getEnvironmentService().getComponent(component.component!.id, projectId);

            return {
                isComponentPublic: component.isPublic,
                version: component.version,
                pipelineId
            }
        }
    }

    const loadData = async () => {
        const { pipelineId, version, isComponentPublic } = await getCurrentDeployment();

        const [{ data: pipeline }, pipelines] = await Promise.all([
            getPipelinesService().getPipelineById(projectId, pipelineId),
            getPipelines(projectId, { pipelineId, envId, componentId, appName })
        ])

        if (!isComponentPublic) {
            getPipelinesService().listGitAccountRepositoryBranches(
                projectId,
                pipeline.gitAccountId!,
                pipeline.repositoryUrl!
            ).then(({ data: { branches } }) => setBranches(branches))
        } else {
            setBranches([]);
        }

        const filteredPipelines = pipelines
            .filter(p => p.type === "deploy")
            .filter(p => {
                if (appName) {
                    return p.targets.some(t => t.name === appName)
                } else {
                    return p.targets.some(t => t.name === componentId)
                }
            });
        const currentDeployment = filteredPipelines.find(p => p.targets.some(t => t.commit.version === version));

        setPipelines(filteredPipelines);
        setPipelineId(pipelineId);
        setActiveDeployment(currentDeployment?.id);
        setLoading(false);
    }

    const handlePipelineTrigger = async (branch: string) => {
        try {
            await getPipelinesService()
                .triggerPipeline(projectId, pipelineId!, { ref: `refs/heads/${branch}` });

            notification.success({
                message: "Build in Progress",
                description: <>
                    It could take a few moments for the changes to take effect.
                    <div>
                        <a href={`/projects/${projectId}/pipelines`}>View in Pipelines</a>
                    </div>
                </>,
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
        } catch (error) {
            notification.error({
                message: "Error triggering build",
                description: (error as any).response?.data?.message || "We encountered an error while trying to trigger a build. Please try again."
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <Space direction="vertical" size="large" className="flex-justify-space-between">
            <div className="flex-justify-space-between flex-align-center">
                <div>
                    <div style={{ fontWeight: 600, fontSize: 16 }}>
                        Deployment Pipelines
                    </div>
                    <div className="gray-text" style={{ fontWeight: 400, fontSize: 14 }}>
                        Only pipelines that triggered deployments on this specific component are displayed here.
                    </div>
                </div>
                <Dropdown
                    trigger={["click"]}
                    placement="bottomRight"
                    menu={{
                        direction: "ltr",
                        items: [
                            ...branches.map(branch => ({
                                key: branch,
                                label: branch,
                                onClick: () => handlePipelineTrigger(branch)
                            }))
                        ]
                    }}
                >
                    <Tooltip
                        title={
                            disableManualBuild ?
                                "No more available build minutes in this plan" :
                                undefined
                        }
                    >
                        <Button
                            disabled={disableManualBuild || loading || branches.length === 0}
                        >
                            Trigger from branch <DownOutlined />
                        </Button>
                    </Tooltip>
                </Dropdown>
            </div>
            <Table
                pagination={false}
                className="ant-table-selectable"
                loading={loading}
                dataSource={pipelines}
                scroll={{ y: "calc(100vh - 440px)" }}
                locale={{
                    emptyText:
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <>
                                    <div>
                                        This {appName ? "app" : "component"} does not have any pipelines yet!
                                    </div>
                                    <div>
                                        Please either push changes on Git or manually trigger a pipeline from the branch.
                                    </div>
                                </>
                            }
                        />
                }}
                columns={[
                    {
                        title: 'Commit',
                        key: 'commit',
                        render: (item: PipelineItem) => (
                            <Space direction="vertical" style={{ fontSize: "12px" }}>
                                {
                                    item.targets.length ?
                                        <div>
                                            {
                                                item.targets
                                                    .filter(t => t.name === appName || t.name === componentId)
                                                    .splice(0, 1)
                                                    .map(target => (
                                                        <div style={{ fontSize: "12px", lineHeight: "20px" }}>
                                                            <b>{target.commit.message} </b>
                                                            {
                                                                item.id === activeDeployment &&
                                                                <Tooltip title="This version of the application is currently deployed in the cloud">
                                                                    <Badge count={
                                                                        <CheckCircleTwoTone twoToneColor={"#389E0D"} style={{ fontSize: "16px" }} />
                                                                    } />
                                                                </Tooltip>
                                                            }
                                                            <div>
                                                                <GitCommitLink version={target.commit.sha || target.commit.version} repoUrl={target.repository} branch={target.commit.name} />
                                                            </div>
                                                        </div>
                                                    ))
                                            }
                                        </div> :
                                        <>No commit to display</>
                                }
                            </Space>
                        )
                    },
                    {
                        key: 'time',
                        title: "Execution time",
                        render: (item: PipelineItem) => (
                            <Space direction="vertical" style={{ fontSize: "12px" }}>
                                <div style={{ fontSize: "13px" }}>
                                    {moment(item.startDate).fromNow()}
                                </div>
                                <div>
                                    <Avatar className="no-border" src={item.initiatorAvatar} size={18} /> {item.initiatorName}
                                </div>
                            </Space>
                        )
                    },
                    {
                        key: 'status',
                        title: "Status",
                        align: "right",
                        render: (build: PipelineItem) => (
                            build.stage === "deploy" ?
                                <DeploymentStatusTag status={build.status} /> :
                                <BuildStatusTag status={build.status} statusCode={build.statusCode} />
                        )
                    }
                ]}
                onRow={build => {
                    return {
                        onClick: () => {
                            setSelectedPipeline(build)
                            setShowPipelineDetails(true);
                        }, // click row
                    };
                }}
            />
            {
                showPipelineDetails ? (
                    <>
                        {
                            selectedPipeline?.build &&
                            <BuildDetailsDrawer
                                type={selectedPipeline.type}
                                projectId={projectId}
                                pipelineId={selectedPipeline.build?.pipelineId!}
                                buildId={selectedPipeline.build?.id!}
                                open={showPipelineDetails}
                                onClose={() => setShowPipelineDetails(false)}
                            />
                        }
                        {
                            selectedPipeline?.targetType === "environment" &&
                            <ComponentDeployDetailsDrawer
                                type={selectedPipeline.type}
                                projectId={projectId}
                                envId={selectedPipeline.envId!}
                                cloudProvider={env.cloudProvider!}
                                iac={env.infrastructureAsCodeTool!}
                                deploymentId={selectedPipeline.id}
                                open={showPipelineDetails}
                                onClose={() => setShowPipelineDetails(false)}
                            />
                        }
                        {
                            selectedPipeline?.targetType === "app" &&
                            <AppDeployDetailsDrawer
                                type={selectedPipeline.type}
                                projectId={projectId}
                                envId={selectedPipeline.envId!}
                                deploymentId={selectedPipeline.id}
                                open={showPipelineDetails}
                                onClose={() => setShowPipelineDetails(false)}
                            />
                        }
                    </>
                ) : null
            }
        </Space>
    )
}