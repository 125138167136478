import { Card, Col, Divider, Dropdown, Form, notification, Row } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { getEnvironmentService } from "../../backend";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import { ComponentSchemaInputProp, ComponentSchemaInputProperties, Dictionary, IComponentDetails } from "../../types";
import ComponentConfigFormItems from "../../components/ComponentConfigFormItems";
import ResourceSettingsStorageExplanation from "../../components/explanations/ResourceSettingsStorageExplanation";
import { LoadingOutlined } from "@ant-design/icons";

const ResourceSettingsStorage = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { projectId, envId } = useParams() as { projectId: string; envId: string };
    const { component } = useOutletContext<{ component: IComponentDetails }>();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loading, setLoading] = useState(false);
    const [storageProps, setStorageProps] = useState<Dictionary<ComponentSchemaInputProp>>({});
    const [inputProps] = useState<ComponentSchemaInputProperties>(component.component.schema.properties.inputs.properties);
    const [references] = useState<string[]>((component.configurations || []).filter(val => val.reference).map(val => val.key));

    useEffect(() => {
        setStorageProps(getPropsInCategory("storage"));

        const initialValues = Object.entries(inputProps).reduce((acc, [key, value]) => {
            // Get the default value from the schema
            const val = component.configurations.find(c => c.key === key)?.value || value.default;
            const defaultValue = value.type === "array" ?
                (val as string)?.split(",") :
                val;
            acc[key] = defaultValue;
            return acc;
        }, {} as Dictionary<string | string[] | number | undefined>);

        form.setFieldsValue(initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = async () => {
        const values = await form.validateFields() as Dictionary<string>;

        const configurations = Object.keys(values).reduce((acc, key) => {
            const value = values[key];
            const config = acc.find(a => a.key === key);

            if (config) {
                config.value = value;
            } else {
                acc.push({
                    key,
                    value,
                    sensitive: !!inputProps[key].sensitive,
                    reference: references.includes(key)
                });
            }
            return acc;
        }, component.configurations);

        setLoading(true);
        await getEnvironmentService().updateResource(
            envId!,
            component.name,
            projectId!,
            {
                componentVersion: component.version,
                configurations
            }
        );
        setLoading(false);
    }

    const deployResource = async () => {
        await getEnvironmentService().deployStage(
            envId!,
            projectId!,
            {
                partial: true,
                resourceVersionOverrides: {
                    [component.name]: component.version
                }
            }
        );
    }

    const getPropsInCategory = (category: string) => {
        return Object.keys(inputProps).reduce((acc, key) => {
            const prop = inputProps[key];
            if (prop.category === category) {
                acc[key] = prop;
            }
            return acc;
        }, {} as Dictionary<ComponentSchemaInputProp>);
    }

    const handleSaveAndDeploy = async () => {
        try {
            setLoading(true);

            await handleSave();
            await deployResource();

            notification.success({
                message: "Storage update in Progress",
                description: <>
                    It could take a few moments for the changes to take effect.
                    <div>
                        <a href={`/projects/${projectId}/pipelines`}>View in Pipelines</a>
                    </div>
                </>,
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
        } catch (error: any) {
            notification.error({
                message: "Application storage update failed",
                description: error.response.data.message
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card bordered>
            <Card.Meta
                title={
                    <>
                        <span>
                            Storage
                        </span>
                        <ExplanationButton
                            content={<ResourceSettingsStorageExplanation />}
                        />
                    </>
                }
                description="Choose the storage solution your application will use to securely manage and store data."
            />
            <Divider />
            <Form
                form={form}
                layout="vertical"
                requiredMark={requiredMark}
                onFinish={handleSaveAndDeploy}
                className="full-width"
            >
                <Row gutter={[24, 12]}>
                    <Col span={24}>
                        <ComponentConfigFormItems
                            formItems={storageProps}
                            required={component.component.schema.properties.inputs.required || []}
                            setFieldsValue={form.setFieldsValue}
                            getFieldValue={form.getFieldValue}
                            references={[]}
                            handleUpdateReferences={() => { }}
                            filterCategories={["storage"]}
                        />
                    </Col>
                </Row>

                <br />

                <Row className="flex-justify-end">
                    <Col>
                        <Dropdown.Button
                            trigger={["click"]}
                            htmlType="submit"
                            loading={loading}
                            menu={{
                                items: [{
                                    key: "save",
                                    label: "Save",
                                    onClick: () => handleSave()
                                }]
                            }}
                        >
                            Save and Deploy
                        </Dropdown.Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default ResourceSettingsStorage;