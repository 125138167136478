import './App.less';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import AppRoot from "./pages/app/AppRoot";
import ProjectPageLayout from "./pages/home/ProjectPageLayout";
import EnvironmentRoot from "./pages/environment/EnvironmentRoot";
import Templates from "./pages/template/Templates";
import CreateFromTemplate from "./pages/template/CreateFromTemplate";
import EnvironmentSettingsGeneral from './pages/environment/EnvironmentSettingsGeneral';
import ProjectRoot from './pages/project/ProjectRoot';
import Login from './pages/auth/Login';
import AuthRoot from './pages/auth/AuthRoot';
import Signup from './pages/auth/Signup';
import SetPassword from './pages/auth/SetPassword';
import EmailVerify from './pages/auth/EmailVerified';
import PrivateOutlet from './components/auth/PrivateOutlet';
import PublicOutlet from './components/auth/PublicOutlet';
import ResetPassword from './pages/auth/ResetPassword';
import ResetPasswordRequest from './pages/auth/ResetPasswordRequest';
import ProjectIntegrationsRoot from './pages/integrations/ProjectIntegrationsRoot';
import ProjectSettingsRoot from './pages/project/ProjectSettingsRoot';
import ProjectEnvironments from './pages/project/ProjectEnvironments';
import EnvironmentSettingsRoot from './pages/environment/EnvironmentSettingsRoot';
import ProjectNew from './pages/project/ProjectNew';
import PricingPlans from './pages/billing/PricingPlans';
import AppSettingsRoot from './pages/app/AppSettingsRoot';
import AppSettingsEnvironmentVars from './pages/app/AppSettingsEnvironmentVars';
import AppSettingsScaling from './pages/app/AppSettingsScaling';
import AppSettingsDeployments from './pages/app/AppSettingsDeployments';
import AppSettingsDangerZone from './pages/app/AppSettingsDangerZone';
import AppPipelineBuilds from './pages/app/AppPipelineBuilds';
import ProjectSettingsGeneral from './pages/project/ProjectSettingsGeneral';
import ProjectSettingsMembers from './pages/project/ProjectSettingsMembers';
import ProjectSettingsBilling from './pages/project/ProjectSettingsBilling';
import ProjectIntegrationsGit from './pages/integrations/ProjectIntegrationsGit';
import ProjectIntegrationsCloud from './pages/integrations/ProjectIntegrationsCloud';
import ProjectIntegrationsContainer from './pages/integrations/ProjectIntegrationsContainer';
import ProjectIntegrationsSlack from './pages/integrations/ProjectIntegrationsSlack';
import ResourceSettingsRoot from './pages/resource/ResourceSettingsRoot';
import ResourceSettingsGeneral from './pages/resource/ResourceSettingsGeneral';
import ResourceSettingsDangerZone from './pages/resource/ResourceSettingsDangerZone';
import AppSettingsPipeline from './pages/app/AppSettingsPipeline';
import ResourceSettingsPipeline from './pages/resource/ResourceSettingsPipeline';
import { env } from "./env";
// import EnvironmentInfraDeploymentDetails from './pages/environment/EnvironmentInfraDeploymentDetails';
// import EnvironmentAppDeploymentDetails from './pages/environment/EnvironmentAppDeploymentDetails';
import EnvironmentRunningTemplates from './pages/environment/EnvironmentRunningTemplates';
import { notification } from 'antd';
import Welcome from './pages/home/Welcome';
import ProjectSettingsUsage from './pages/project/ProjectSettingsUsage';
import MetaTag from './components/MetaTag';
import ProjectsRoot from './pages/projects/ProjectsRoot';
import ProjectsOverview from './pages/projects/ProjectsOverview';
import AppSettingsCustomDomain from './pages/app/AppSettingsCustomDomain';
import ResourceSettingsCustomDomain from './pages/resource/ResourceSettingsCustomDomain';
import PipelineBuildDetails from './components/PipelineBuildDetails';
import PageNotFound from './pages/home/PageNotFound';
import ProjectSettingsCostReport from './pages/project/ProjectSettingsCostReport';
import ResourceSettingsScaling from './pages/resource/ResourceSettingsScaling';
import ResourceSettingsAlarms from './pages/resource/ResourceSettingsAlarms';
import ResourceSettingsStorage from './pages/resource/ResourceSettingsStorage';
import ResourceSettingsVariables from './pages/resource/ResourceSettingsVariables';
import { setDefaultPlan } from './utils/local-storage';
import EnvironmentOverview from './pages/environment/EnvironmentOverview';
import EnvironmentKubernetesAppDetails from './pages/environment/EnvironmentKubernetesAppDetails';
import EnvironmentComponentDetails from './pages/environment/EnvironmentComponentDetails';
import HomePageLayout from './pages/home/HomePageLayout';
import ProjectPipelines from './pages/project/ProjectPipelines';
import CostExplorer from './pages/cost/CostExplorer';
import { useEffect } from 'react';
import EnvironmentFargateAppDetails from './pages/environment/EnvironmentFargateAppDetails';
import ProjectMonitoring from './pages/project/ProjectMonitoring';
import ProjectAlarms from './pages/project/ProjectAlarms';
import EnvironmentDeployApp from './pages/environment/EnvironmentDeployApp';
import EnvironmentDeployComponent from './pages/environment/EnvironmentDeployComponent';
import KubernetesSettingsGeneral from './pages/resource/KubernetesSettingsGeneral';
import KubernetesSettingsRoot from './pages/resource/KubernetesSettingsRoot';
import KubernetesSettingsDangerZone from './pages/resource/KubernetesSettingsDangerZone';
import TemplateRoot from './pages/template/TemplateRoot';
import EnvironmentSettingsDangerZone from './pages/environment/EnvironmentSettingsDangerZone';
import ClusterMonitoringSettings from './pages/resource/ClusterMonitoringSettings';
import ProjectMonitoringLogin from './pages/project/ProjectMonitoringLogin';
import TemplateComponentDetails from './pages/template/TemplateComponentDetails';
import TemplateAppDetails from './pages/template/TemplateAppDetails';
import ProjectCreateAlarm from './pages/project/ProjectCreateAlarm';
import ProjectChat from './pages/project/ProjectChat';

const App = () => {
  // Configure notification component
  notification.config({
    duration: 6
  });

  // Redirect to the old portal if state = redirect:portal
  const params = new URLSearchParams(window.location.search);
  const state = params.get("state") || "";
  const code = params.get("code") || "";
  const type = params.get("type") || "";

  if (state === "redirect:portal") {
    window.location.href = `${env.REACT_APP_OLD_PORTAL_URL}/login?code=${code}&state=${state}&type=${type}`;
  }
  //

  // Set default plan
  const defaultPlan = params.get("default_plan");
  if (defaultPlan) {
    setDefaultPlan(defaultPlan);
  }

  useEffect(() => {
    const onOffline = () => notification.error({ message: "No internet connection", description: "Please check your connection." });
    const onOnline = () => notification.success({ message: "Internet connection is back online" });

    window.addEventListener('offline', onOffline);
    window.addEventListener("online", onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener("online", onOnline);
    }
  },[]);

  return (
    <Router>
      <MetaTag defaultTags={true} />
      <Routes>
        <Route element={<PublicOutlet />}>
          <Route path='/' element={<Navigate to="/auth/login" />} />
          <Route path="auth" element={<AuthRoot />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="signup/verify/:email/:code" element={<EmailVerify />} />
            <Route path="set-password/:email/:code" element={<SetPassword />} />
            <Route path="reset-password" element={<ResetPasswordRequest />} />
            <Route path="reset-password/:email/:code" element={<ResetPassword />} />
          </Route>
          <Route element={<HomePageLayout />}>
            {/* <Route path="templates" element={<Templates />} /> */}
            <Route path="templates/new" element={<CreateFromTemplate />} />
          </Route>

          {/* Backward compatibility with the old implementation */}
          <Route path="/" element={<AuthRoot />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="account/verify/:email/:code" element={<EmailVerify />} />
            <Route path="set-password/:email/:code" element={<SetPassword />} />
            <Route path="reset-password" element={<ResetPasswordRequest />} />
            <Route path="account/reset/:email/:code" element={<ResetPassword />} />
          </Route>
        </Route>
        <Route element={<PrivateOutlet />}>
          {/* Projects */}
          <Route element={<HomePageLayout />}>
            <Route path="projects" element={<ProjectsRoot />} >
              <Route index element={<ProjectsOverview />} />
            </Route>
            <Route path="welcome" element={<Welcome />} />
          </Route>

          {/* Project Root */}
          <Route element={<ProjectPageLayout />}>
            {/* Project Environments */}
            <Route path="projects/:projectId" element={<ProjectRoot />}>
              <Route index element={<ProjectEnvironments />} />
              <Route path="environments" element={<ProjectEnvironments />} />
            </Route>

            {/* Project Deployments */}
            <Route path="projects/:projectId">
              <Route path="chat" element={<ProjectChat />} />
              <Route path="pipelines" element={<ProjectPipelines />} />
              <Route path="monitoring" element={<ProjectMonitoring />} />
              <Route path=":envId/:clusterId/:appName/monitoring" element={<ProjectMonitoring />} />
              <Route path=":envId/:clusterId/:namespace/:appName/monitoring" element={<ProjectMonitoring />} />
              <Route path="monitoring/login" element={<ProjectMonitoringLogin />} />
              <Route path="monitoring/alarms" element={<ProjectAlarms />} />
              <Route path="monitoring/:clusterType/alarm/:metricGroup" element={<ProjectCreateAlarm />} />
            </Route>

            {/* Project Other */}
            <Route path="projects/:projectId">
              <Route path="cost-explorer" element={<CostExplorer />} />
              <Route path="templates" element={<Templates />} />
              <Route path="integrations" element={<ProjectIntegrationsRoot />}>
                <Route index element={<ProjectIntegrationsGit />} />
                <Route path="git" element={<ProjectIntegrationsGit />} />
                <Route path="cloud" element={<ProjectIntegrationsCloud />} />
                <Route path="container" element={<ProjectIntegrationsContainer />} />
                <Route path="slack" element={<ProjectIntegrationsSlack />} />
              </Route>
              <Route path="settings" element={<ProjectSettingsRoot />}>
                <Route index element={<ProjectSettingsGeneral />} />
                <Route path="general" element={<ProjectSettingsGeneral />} />
                <Route path="usage" element={<ProjectSettingsUsage />} />
                <Route path="cost-report" element={<ProjectSettingsCostReport />} />
                <Route path="members" element={<ProjectSettingsMembers />} />
                <Route path="billing" element={<ProjectSettingsBilling />} />
              </Route>
            </Route>

            {/* <Route path="templates" element={<Templates />} /> */}
            <Route path="projects/new" element={<ProjectNew />} />
            <Route path="templates/new" element={<CreateFromTemplate />} />

            <Route path="projects/:projectId">
              {/* Environments */}
              <Route path="environments/:envId" element={<EnvironmentRoot />}>
                <Route path="" element={<EnvironmentOverview />}>
                  <Route path="components/:componentId/apps/:appName/:section" element={<EnvironmentFargateAppDetails />} />
                  <Route path="components/:componentId/apps/:namespace/:appName/:section" element={<EnvironmentKubernetesAppDetails />} />
                  <Route path="components/:componentId/:section" element={<EnvironmentComponentDetails />} />
                </Route>
                {/* <Route path="deployments/infrastructure/:deploymentId" element={<EnvironmentInfraDeploymentDetails />} /> */}
                {/* <Route path="deployments/apps/:clusterId/:namespace/:appName/:deploymentId" element={<EnvironmentAppDeploymentDetails />} /> */}
                <Route path="active-templates" element={<EnvironmentRunningTemplates />} />
              </Route>

              {/* Environment settings */}
              <Route path="environments/:envId" element={<EnvironmentRoot settings={true} />}>
                <Route path="settings" element={<EnvironmentSettingsRoot />}>
                  <Route index element={<EnvironmentSettingsGeneral />} />
                  <Route path="general" element={<EnvironmentSettingsGeneral />} />
                  <Route path="danger-zone" element={<EnvironmentSettingsDangerZone />} />
                </Route>
              </Route>

              {/* App settings */}
              <Route path="environments/:envId" element={<EnvironmentRoot settings={true} />}>
                <Route path="components/:componentId/:clusterId/apps/:namespace/:appName/settings" element={<AppSettingsRoot />}>
                  <Route index element={<AppSettingsEnvironmentVars />} />
                  <Route path="environment" element={<AppSettingsEnvironmentVars />} />
                  <Route path="domain" element={<AppSettingsCustomDomain />} />
                  <Route path="pipeline" element={<AppSettingsPipeline />} />
                  <Route path="scaling" element={<AppSettingsScaling />} />
                  <Route path="danger-zone" element={<AppSettingsDangerZone />} />
                </Route>
              </Route>

              {/* Component settings */}
              <Route path="environments/:envId" element={<EnvironmentRoot settings={true} />}>
                {/* Standalone component */}
                <Route path="components/:componentId/settings" element={<ResourceSettingsRoot />}>
                  <Route index element={<ResourceSettingsGeneral />} />
                  <Route path="general" element={<ResourceSettingsGeneral />} />
                  <Route path="pipeline" element={<ResourceSettingsPipeline />} />
                  <Route path="scaling" element={<ResourceSettingsScaling />} />
                  <Route path="alarms" element={<ResourceSettingsAlarms />} />
                  <Route path="domain" element={<ResourceSettingsCustomDomain />} />
                  <Route path="storage" element={<ResourceSettingsStorage />} />
                  <Route path="variables" element={<ResourceSettingsVariables />} />
                  <Route path="advanced" element={
                    <ResourceSettingsGeneral filterCategories={["advanced"]} />
                  } />
                  <Route path="danger-zone" element={<ResourceSettingsDangerZone />} />
                </Route>
                {/* Cluster */}
                <Route path="components/:componentId/:clusterId/settings" element={<ResourceSettingsRoot />}>
                  <Route index element={<ResourceSettingsGeneral />} />
                  <Route path="general" element={<ResourceSettingsGeneral />} />
                  <Route path="pipeline" element={<ResourceSettingsPipeline />} />
                  <Route path="scaling" element={<ResourceSettingsScaling />} />
                  <Route path="alarms" element={<ResourceSettingsAlarms />} />
                  <Route path="domain" element={<ResourceSettingsCustomDomain />} />
                  <Route path="storage" element={<ResourceSettingsStorage />} />
                  <Route path="variables" element={<ResourceSettingsVariables />} />
                  <Route path="monitoring" element={<ClusterMonitoringSettings />} />
                  <Route path="advanced" element={
                    <ResourceSettingsGeneral filterCategories={["advanced"]} />
                  } />
                  <Route path="danger-zone" element={<ResourceSettingsDangerZone />} />
                </Route>
                <Route path="components/:clusterId/kubernetes/settings" element={<KubernetesSettingsRoot />}>
                  <Route index element={<KubernetesSettingsGeneral />} />
                  <Route path="general" element={<KubernetesSettingsGeneral />} />
                  <Route path="monitoring" element={<ClusterMonitoringSettings />} />
                  <Route path="danger-zone" element={<KubernetesSettingsDangerZone />} />
                </Route>
              </Route>

              {/* Create from Templates */}
              <Route path="environments/:envId" element={<EnvironmentRoot templates={true} />}>
                <Route path="templates/:templateId" element={<CreateFromTemplate hideHeader={true} />} />
                <Route path="components/:componentId/:clusterId/templates/:templateId" element={<CreateFromTemplate hideHeader={true} />} />
              </Route>

              {/* Add existing Component in environment */}
              <Route path="environments/:envId" element={<EnvironmentRoot existingTemplate={true} />}>
                <Route path="templates/:templateId/add-component" element={<EnvironmentDeployComponent />} />
              </Route>

              {/* Add existing App in environment */}
              <Route path="environments/:envId" element={<EnvironmentRoot existingTemplate={true} />}>
                <Route path="templates/:templateId/add-app" element={<EnvironmentDeployApp />} />
                <Route path="components/:componentId/:clusterId/templates/:templateId/add-app" element={<EnvironmentDeployApp />} />
              </Route>

              {/* Apps */}
              <Route path="environments/:envId/apps/:clusterId/:namespace/:appName" element={<AppRoot />}>
                <Route path="pipeline" element={<AppPipelineBuilds />} />
                <Route path="pipeline/:pipelineId/build/:buildId" element={<PipelineBuildDetails />} />
                <Route path="settings" element={<AppSettingsRoot />}>
                  <Route index element={<AppSettingsEnvironmentVars />} />
                  <Route path="environment" element={<AppSettingsEnvironmentVars />} />
                  <Route path="domain" element={<AppSettingsCustomDomain />} />
                  <Route path="pipeline" element={<AppSettingsPipeline />} />
                  <Route path="scaling" element={<AppSettingsScaling />} />
                  <Route path="deployments" element={<AppSettingsDeployments />} />
                  <Route path="danger-zone" element={<AppSettingsDangerZone />} />
                </Route>
              </Route>

              {/* Templates */}
              <Route path="templates" element={<TemplateRoot />}>
                <Route path=":templateId" element={<CreateFromTemplate hideHeader={true} />} />
              </Route>
              <Route path="templates/components/:componentId/details" element={<TemplateComponentDetails />} />
              <Route path="templates/apps/:appId/details" element={<TemplateAppDetails />} />
            </Route>

            <Route path="/pricing/:action" element={<PricingPlans />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
    </Router >
  );
};

export default App;