import { CheckCircleOutlined, CrownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Dropdown, Form, Modal, notification, Row, Space } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { getEnvironmentService, getProjectService } from "../../backend";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import { ComponentSchemaInputProp, ComponentSchemaInputProperties, Dictionary, IComponentDetails, PricingPlan } from "../../types";

import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { PricingPlanNames } from "../../enums/enums";
import { useCurrentProject } from "../../contexts/Project";
import Title from "antd/lib/typography/Title";
import AlarmsScreenshot from "../../assets/alarms.png"
import { getDetailedPaymentPlan } from "../../utils/payment-plans";
import ComponentConfigFormItems from "../../components/ComponentConfigFormItems";
import ResourceSettingsAlarmsExplanation from "../../components/explanations/ResourceSettingsAlarmsExplanation";


const ResourceSettingsAlarms = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { projectId, envId } = useParams();
    const { component } = useOutletContext<{ component: IComponentDetails }>();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loading, setLoading] = useState(false);
    const { paymentPlan: userPaymentPlan, id, freeTrials } = useRecoilValue(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();
    const [nextPaymentPlan, setNextPaymentPlan] = useState<PricingPlan>();
    const [alarmProps, setAlarmProps] = useState<Dictionary<ComponentSchemaInputProp>>({});
    const [inputProps] = useState<ComponentSchemaInputProperties>(component.component.schema.properties.inputs.properties);
    const [references] = useState<string[]>((component.configurations || []).filter(val => val.reference).map(val => val.key));

    useEffect(() => {
        async function loadData() {
            const advancedPlan = await getDetailedPaymentPlan(PricingPlanNames.ADVANCED);
            setNextPaymentPlan(advancedPlan);
        }
        loadData();
    }, [userPaymentPlan]);

    useEffect(() => {
        setAlarmProps(getPropsInCategory("alarm"));

        const initialValues = Object.entries(inputProps).reduce((acc, [key, value]) => {
            // Get the default value from the schema
            const val = component.configurations.find(c => c.key === key)?.value || value.default;
            const defaultValue = value.type === "array" ?
                (val as string)?.split(",") :
                val;
            acc[key] = defaultValue;
            return acc;
        }, {} as Dictionary<string | string[] | number | undefined>);

        form.setFieldsValue(initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = async () => {
        const values = await form.validateFields() as Dictionary<string>;

        const configurations = Object.keys(values).reduce((acc, key) => {
            const value = values[key];
            const config = acc.find(a => a.key === key);

            if (config) {
                config.value = value.toString();
            } else {
                acc.push({
                    key,
                    value: value.toString(),
                    sensitive: !!inputProps[key].sensitive,
                    reference: references.includes(key)
                });
            }
            return acc;
        }, component.configurations);

        setLoading(true);
        await getEnvironmentService().updateResource(
            envId!,
            component.name,
            projectId!,
            {
                componentVersion: component.version,
                configurations
            }
        );
        setLoading(false);
    }

    const deployResource = async () => {
        await getEnvironmentService().deployStage(
            envId!,
            projectId!,
            {
                partial: true,
                resourceVersionOverrides: {
                    [component.name]: component.version
                }
            }
        );
    }

    const handleSaveAndDeploy = async () => {
        try {
            setLoading(true);

            await handleSave();
            await deployResource();

            notification.success({
                message: "Scaling in Progress",
                description: <>
                    It could take a few moments for the changes to take effect.
                    <div>
                        <a href={`/projects/${projectId}/pipelines`}>View in Pipelines</a>
                    </div>
                </>,
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
        } catch (error: any) {
            notification.error({
                message: "Application scaling failed",
                description: error.response.data.message
            });
        } finally {
            setLoading(false);
        }
    }

    const getPropsInCategory = (category: string) => {
        return Object.keys(inputProps).reduce((acc, key) => {
            const prop = inputProps[key];
            if (prop.category === category) {
                acc[key] = prop;
            }
            return acc;
        }, {} as Dictionary<ComponentSchemaInputProp>);
    }

    const isTrialEligible = (planId: string) => {
        return freeTrials && !(freeTrials as Dictionary<boolean>)[planId];
    }

    const handleSelectPlan = async () => {
        try {
            await getProjectService().changeProjectSubscription(
                id,
                {
                    paymentPlanId: nextPaymentPlan!.id,
                    priceId: nextPaymentPlan!.priceId!
                }
            );
            await updateCurrentProject(id);
        } catch (error: any) {
            notification.error({
                message: `Error activating the ${nextPaymentPlan!.name} plan`,
                description: <>
                    {error.response.data.message}&nbsp;
                    <a href={`/projects/${id}/settings/billing`}>Go to Billing</a> to check your settings.
                </>
            })
        }
    }

    const showSelectPlanConfirm = () => Modal.confirm({
        title: `Activate ${nextPaymentPlan!.name} plan`,
        content:
            <Space direction='vertical'>
                <div>
                    You are about to activate the {nextPaymentPlan!.name} plan that will cost {nextPaymentPlan!.price} per month.
                </div>
                {
                    isTrialEligible(nextPaymentPlan!.id) ?
                        <div>
                            <b>7 day free trial</b> will be applied.
                        </div> :
                        <div>
                            Your credit card will be <b>charged immediately</b> because you've already used your free trial period.
                        </div>
                }
            </Space>,
        okText: `Activate ${nextPaymentPlan!.name} plan`,
        centered: true,
        async onOk() {
            return handleSelectPlan();
        }
    });

    const disabledAppScaling = (<div className="ant-card ant-card-bordered">
        <Row>
            <Col offset={2} span={9} className="flex-align-center">
                <Space direction="vertical" size={24}>
                    <Space size={16} direction="vertical">
                        <Space direction="vertical" size={0}>
                            <Title level={4} style={{ color: "green", fontWeight: "bold", }}>
                                Unlock Advanced Alerting
                            </Title>
                        </Space>
                        <div>
                            <ul className="no-margin" style={{ paddingLeft: 0, listStyleType: "none" }}>
                                <li><CheckCircleOutlined style={{ color: "green" }} /> Define precise conditions for alarm triggers</li>
                                <li><CheckCircleOutlined style={{ color: "green" }} /> Stay ahead of potential issues</li>
                                <li><CheckCircleOutlined style={{ color: "green" }} /> Gain proactive control</li>
                            </ul>
                        </div>
                    </Space>
                    <Button
                        type="primary"
                        onClick={() => showSelectPlanConfirm()}>
                        <Space>
                            <CrownOutlined />
                            Unlock Alarms
                        </Space>
                    </Button>
                </Space>
            </Col>
            <Col offset={1} span={12}>
                <img src={AlarmsScreenshot} alt="cost-explorer" className="cost-dashboard-screenshot" />
            </Col>
        </Row>
    </div>)

    const appScaling = (
        <Form
            form={form}
            layout="vertical"
            requiredMark={requiredMark}
            onFinish={handleSaveAndDeploy}
            className="full-width"
        >
            <Row gutter={[24, 12]}>
                <Col span={24}>
                    <ComponentConfigFormItems
                        formItems={alarmProps}
                        required={component.component.schema.properties.inputs.required || []}
                        setFieldsValue={form.setFieldsValue}
                        getFieldValue={form.getFieldValue}
                        references={[]}
                        handleUpdateReferences={() => { }}
                        filterCategories={["alarm"]}
                    />
                </Col>
            </Row>

            <br />

            <Row className="flex-justify-end">
                <Col>
                    <Dropdown.Button
                        trigger={["click"]}
                        htmlType="submit"
                        loading={loading}
                        disabled={userPaymentPlan?.id === "FREE"}
                        menu={{
                            items: [{
                                key: "save",
                                label: "Save",
                                onClick: () => handleSave()
                            }]
                        }}
                    >
                        Save and Deploy
                    </Dropdown.Button>
                </Col>
            </Row>
        </Form >
    )

    return (
        <Card bordered>
            <Card.Meta
                title={
                    <>
                        <span>
                            Alarms
                        </span>
                        <ExplanationButton
                            content={<ResourceSettingsAlarmsExplanation />}
                        />
                    </>
                }
                description="Define custom thresholds for performance and error metrics to activate alarms when specific conditions are reached."
            />
            <Divider />
            {
                userPaymentPlan?.id === PricingPlanNames.FREE || userPaymentPlan?.id === PricingPlanNames.STARTER ?
                    disabledAppScaling :
                    appScaling
            }
        </Card>
    );
}

export default ResourceSettingsAlarms;