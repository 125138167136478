import { Empty, List, Modal, Select, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import { getElasticSearchService, getEnvironmentService } from "../../backend";
import { ClockCircleOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { StageDeploymentComponentMetadata, StageDeploymentComponentMetadataCommit } from "@microtica/ms-elasticsearch-sdk";
import { isResourceDeployed } from "../DeploymentStatusTag";
import GitCommitLink from "../GitCommitLink";
import { ComponentVersion, Dictionary } from "../../types";
import { PipelineBuildDetails } from "@microtica/ms-ap-sdk";
import moment from "moment";
import { useNavigate } from "react-router";

const { Text } = Typography;

interface DeployMultipleComponentsModalProps {
    projectId: string;
    envId: string;
    lastDeploymentId?: string;
    onOk?: () => void;
    onCancel?: () => void;
    open?: boolean;
}

interface Component {
    name: string;
    componentId: string;
    version: string;
    initialVersion: string;
    versions: {
        name: string;
        date?: number;
        commit?: StageDeploymentComponentMetadataCommit;
        repoUrl?: string;
    }[];
    metadata?: StageDeploymentComponentMetadata;
    loading?: boolean;
    updated?: boolean;
    status: string;
}

const DeployMultipleComponentsModal = ({
    projectId,
    envId,
    lastDeploymentId,
    open,
    onOk,
    onCancel
}: DeployMultipleComponentsModalProps) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [components, setComponents] = useState<Component[]>([]);
    const [filteredComponents, setFilteredComponents] = useState<Component[]>([]);
    const [filter, setFilter] = useState("");
    const [enableDeploy, setEnableDeploy] = useState(false);
    const [deploying, setDeploying] = useState(false);

    console.log("lastDeploymentId", lastDeploymentId)

    useEffect(() => {

        const load = async () => {
            const [
                { data: { resources: components } },
                { data: { response: envDeployments } }
            ] = await Promise.all([
                getEnvironmentService().getStageResources(envId, projectId),
                getElasticSearchService().getStageDeploymentHistory(projectId, envId, lastDeploymentId)
            ])

            const lastDeployment = lastDeploymentId ? envDeployments[0] : envDeployments.find(d => isResourceDeployed(d.status));

            setComponents(components.map(c => {
                const resource = lastDeployment ? (lastDeployment.resources || []).find(r => r.name === c.name) : undefined;
                return {
                    name: c.name,
                    componentId: c.component.id,
                    initialVersion: c.component.version!,
                    version: c.component.version!,
                    versions: resource ? [{
                        name: resource.component.version,
                        date: new Date(lastDeployment?.timestamp!).getTime(),
                        commit: resource?.component.metadata.commit,
                        repoUrl: resource?.component.metadata.repository
                    }] : [{
                        name: c.component.version!,
                        date: new Date(c.lastDeployed!).getTime()
                    }],
                    metadata: resource?.component.metadata,
                    status: c.status
                }
            }))

            setLoading(false);
        }
        load();

    }, []);

    useEffect(() => {
        setFilteredComponents(
            components
                .filter(c => filter ? c.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) : true)
        );
    }, [components, filter]);

    useEffect(() => {
        if (components.some(c => c.initialVersion !== c.version || !isResourceDeployed(c.status))) {
            setEnableDeploy(true);
        } else {
            setEnableDeploy(false);
        }

    }, [components]);

    const loadComponentVersions = async (component: Component) => {
        setComponents(
            components.map(c => {
                if (c.name === component.name) {
                    return {
                        ...c,
                        loading: true
                    };
                } else {
                    return c;
                }
            })
        );

        const { data: versions } = await getEnvironmentService().getComponentBuilds(component.componentId, projectId);

        const componentVersions: ComponentVersion[] = (versions.builds as unknown as PipelineBuildDetails[] || [])
            .sort((a, b) => {
                if ((new Date(a.stopDate!) as any) > (new Date(b.stopDate!) as any)) return -1;
                if ((new Date(a.stopDate!) as any) < (new Date(b.stopDate!) as any)) return 1;
                return 0;
            })
            .sort((a) => a.name === "latest" ? -1 : 0)
            .filter(build => build.status === "SUCCEEDED")
            .map(build => ({
                name: build.id,
                date: build.stopDate!,
                commit: build.metadata.commit,
                repoUrl: build.metadata.repository
            }));

        setComponents(
            components.map(c => {
                if (c.name === component.name) {
                    return {
                        ...c,
                        versions: componentVersions,
                        loading: false
                    };
                } else {
                    return c;
                }
            })
        );
    }

    const handleSelectVersion = (component: Component, version: string) => {
        setComponents(
            components.map(c => {
                if (c.name === component.name) {
                    return {
                        ...c,
                        version,
                        updated: version !== component.initialVersion
                    };
                } else {
                    return c;
                }
            })
        );
    }

    const handleDeploy = async () => {

        const updatedComponents = components
            .filter(c => c.version !== c.initialVersion || !isResourceDeployed(c.status))
            .reduce((acc, c) => {
                acc[c.name] = c.version;
                return acc;
            }, {} as Dictionary<string>)

        try {
            setDeploying(true);
            await getEnvironmentService().deployStage(
                envId,
                projectId,
                {
                    partial: true,
                    resourceVersionOverrides: updatedComponents
                }
            );
            notification.success({
                message: "Deployment in Progress",
                description: <>
                    It could take a few moments for the changes to take effect.
                    <div>
                        <a href={`/projects/${projectId}/pipelines`}>View in Pipelines</a>
                    </div>
                </>,
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
        } catch (error: any) {
            notification.error({
                message: "Error while trying to deploy resource",
                description: error?.response?.data?.message ? error?.response?.data?.message : "We encountered an error while trying to deploy this resource. Please try again."
            });
        } finally {
            setDeploying(false);
        }
    }

    return <Modal
        width="800px"
        open={open}
        title={
            <div>
                <div>Bulk Component Deployment</div>
                <div className="gray-text" style={{ fontSize: "12px", fontWeight: 500 }}>
                    Select and deploy versions of multiple components in one go.
                </div>
            </div>
        }
        okText="Deploy"
        onOk={() => {
            return handleDeploy();
        }}
        okButtonProps={{
            loading: deploying,
            disabled: !enableDeploy
        }}
        onCancel={onCancel}
        className="ant-scollable-modal"
        closable
        centered
    >

        <br />

        <List
            header={
                <Search className="full-width" placeholder="Search components" onSearch={setFilter} allowClear />
            }
            loading={loading}
            dataSource={filteredComponents}
            locale={{
                emptyText: <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No components found for the search criteria."
                />
            }}
            renderItem={(item) => (
                <List.Item className="no-border">
                    <List.Item.Meta
                        title={
                            <Select
                                value={item.version}
                                loading={item.loading}
                                onDropdownVisibleChange={async (open) => {
                                    if (open) {
                                        await loadComponentVersions(item);
                                    }
                                }}
                                className="ant-select-deploy-versions full-width"
                                labelRender={(props) => {
                                    return <div className="hide-time-label">
                                        <Text style={{ fontWeight: 600 }}>
                                            {item.name} {item.updated ? <EditOutlined style={{ color: "var(--primary-color)" }} /> : undefined}
                                        </Text>
                                        {props.label}
                                    </div>
                                }}
                                onChange={(value) => handleSelectVersion(item, value)}
                            >
                                {
                                    item.versions.map(componentVersion => (
                                        <Select.Option value={componentVersion.name} key={componentVersion.name} className=" flex-align-center">
                                            <div className="flex-justify-space-between flex-align-center">
                                                <Text ellipsis style={{ marginRight: "10px" }}>
                                                    {componentVersion.commit?.message || componentVersion.name}
                                                </Text>
                                                <Text className="gray-text time-label">
                                                    <ClockCircleOutlined /> {moment(componentVersion.date).fromNow()}
                                                </Text>
                                            </div>
                                            <Text style={{ fontSize: 12, marginRight: "10px" }} >
                                                {
                                                    componentVersion.commit ?
                                                        <GitCommitLink
                                                            version={componentVersion.commit.version}
                                                            commitType={componentVersion.commit.type}
                                                            repoUrl={componentVersion.repoUrl}
                                                            branch={componentVersion.commit.name}
                                                        /> :
                                                        componentVersion.name
                                                }

                                            </Text>
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        }
                    />
                </List.Item>
            )}
        />
        <br />
    </Modal>
}

export default DeployMultipleComponentsModal;