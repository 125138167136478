import { ProChat, ProChatInstance } from '@ant-design/pro-chat';
import { Avatar, Badge } from 'antd';
import MicroticaLogo from "../../assets/logo-color-circle.svg";
import { UserOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import { useParams } from 'react-router';
import PageContentWrapper from '../../components/PageContentWrapper';
import { PageHeader } from '@ant-design/pro-layout';
import { getAIService, isAIServiceEnabled } from '../../backend';
import MessageCard from '../../components/cards/MessageCard';
// import moment from 'moment';
// import GitCommitLink from '../../components/GitCommitLink';
// import { getKubeService, getPipelinesService } from '../../backend';
// import { OpenAI } from "openai";
// import { ChatCompletionTool } from 'openai/resources';
// import { ClockCircleOutlined } from '@ant-design/icons';
// import { Button, Card, Form, Select } from 'antd';

interface IMessageExtraType {
    props: {
        data: {
            role?: string
        }
    }
}

const ProjectChat = () => {
    const ref = useRef<ProChatInstance | undefined>();
    const { projectId } = useParams() as { projectId: string; }

    const handleUserQuestion = async (question: string) => {
        const { data: { answer } } = await getAIService().getAnswerFromMicroticaDocs(projectId, {
            question
        });
        return answer;
    }

    return (
        <PageContentWrapper
            size="medium"
            header={
                <PageHeader
                    title={
                        <>
                            Chat
                        </>
                    }
                />
            }
        >
            <div className="chat">
                {!isAIServiceEnabled(projectId) ?
                    <MessageCard text="AI assistant is not available for this project!" type="info" /> :
                    <ProChat
                        chatRef={ref}
                        helloMessage={'Hi! How can I help you? You can ask me anything about Microtica.'}
                        request={async (messages: any[]) => await handleUserQuestion(messages.pop().content)}
                        chatItemRenderConfig={{
                            contentRender: (props, defaultDom) => {
                                if (props?.originData?.role === "user") {
                                    return <div className='chat-primary-color-bg'>
                                        {defaultDom}
                                    </div>
                                }
                                return defaultDom;
                            },
                            actionsRender: false, // hide Copy, Regenerate, Delete, Edit actions for message
                            avatarRender: (props, _defaultDom) => {
                                const role = (props.messageExtra as IMessageExtraType).props?.data?.role || "user";
                                // Microtica assistant
                                if (props.messageExtra && role === "user") {
                                    return <Avatar icon={<UserOutlined />} style={{ marginTop: "5px" }} />
                                }
                                return <Badge
                                    count={'🤖'}
                                    style={{
                                        backgroundColor: "#fff",
                                        padding: 0,
                                        height: "16px",
                                        minWidth: "fit-content",
                                        alignItems: "center",
                                        transform: "translate(40%, -45%)",
                                        fontSize: "16px",
                                        marginTop: "2px"
                                    }}
                                >
                                    <img src={MicroticaLogo} style={{ height: "32px", marginTop: "5px" }} alt="microtica" />
                                </Badge>
                            },
                        }}
                        locale='en-US'
                    />
                }
            </div>
        </PageContentWrapper>
    );
}
/*

interface IAppVersion {
    name?: string;
    date?: number;
    commit?: {
        message?: string
        type?: string,
        name?: string,
        user?: {
            avatar?: string,
            name?: string,
            externalId?: string
        }
    }
}

// TODO implement the third scenario (when it's finished)

// TODO move openAI logic to BE
const OPENAI = {
    MODEL_NAME: "ft:gpt-3.5-turbo-0125:microtica::9lDiowlF",
    API_KEY: "sk-proj-t68YopbTpPTNWEMmpVI1T3BlbkFJtpruaWPzvQFh0sogfJ6x"
}

const microticaFunctions = [
    {
        "type": "function",
        "function": {
            "name": "findPipelineByRepositoryName",
            "description": "Returns a single pipeline by its repositoryName property",
            "parameters": {
                "type": "object",
                "properties": {
                    "repositoryName": {
                        "type": "string",
                        "description": "Name of the project"
                    }
                },
                "required": [
                    "repositoryName"
                ]
            }
        }
    },
    {
        "type": "function",
        "function": {
            "name": "triggerPipeline",
            "description": "Triggers pipeline build for the given pipelineId from the given branch",
            "parameters": {
                "type": "object",
                "properties": {
                    "pipelineId": {
                        "type": "string",
                        "description": "Id of the Microtica pipeline"
                    },
                    "branch": {
                        "type": "string",
                        "description": "Name of the git branch"
                    }
                },
                "required": [
                    "pipelineId",
                    "branch"
                ]
            }
        }
    },
    {
        "type": "function",
        "function": {
            "name": "listAvailableVersions",
            "description": "List Available Versions",
            "parameters": {
                "type": "object",
                "properties": {
                    "app": {
                        "type": "string",
                        "description": "Name of the app to list versions"
                    }
                },
                "required": [
                    "app"
                ]
            }
        }
    },
    {
        "type": "function",
        "function": {
            "name": "deployVersion",
            "description": "Deploy specific app version",
            "parameters": {
                "type": "object",
                "properties": {
                    "app": {
                        "type": "string",
                        "description": "Name of the app to deploy"
                    },
                    "version": {
                        "type": "string",
                        "description": "Version of the app to deploy"
                    },
                    "environment": {
                        "type": "string",
                        "description": "Environemnt name to deploy the app"
                    }
                },
                "required": [
                    "app",
                    "environemnt",
                    "version"
                ]
            }
        }
    }
] as Array<ChatCompletionTool>;

const ProjectChat = () => {
    const ref = useRef<ProChatInstance | undefined>();
    const [openaiMessages, setOpenaiMessages] = useState<any[]>([]);
    const { projectId } = useParams() as { projectId: string; }
    const openai = new OpenAI({
        apiKey: OPENAI.API_KEY,
        dangerouslyAllowBrowser: true
    });

    const { Text } = Typography;
    const findPipelineByRepositoryName = async (repositoryName: string) => {
        try {
            const { data: { pipelines } } = await getPipelinesService().listPipelines(projectId);

            const pipelinesWithRepositoryNames = pipelines.map(pipeline => ({
                ...pipeline,
                repositoryName: pipeline.repositoryUrl!.split("/").slice(3).join("/")
            }));

            const pipeline = pipelinesWithRepositoryNames.find(pipeline => pipeline.repositoryName === repositoryName)

            if (pipeline) {
                const { data: { branches } } = await getPipelinesService().listGitAccountRepositoryBranches(
                    projectId,
                    pipeline.gitAccountId!,
                    pipeline.repositoryUrl!
                )
                return JSON.stringify({
                    branches,
                    pipelineName: pipeline.name,
                    pipelineId: pipeline.id
                });
            } else {
                throw Error("Pipeline not found!");
            }
        } catch (error) {
            console.error("findPipelineByRepositoryName error", error)
        }
    }

    const triggerPipeline = async (pipelineId: string, branch: string) => {
        try {
            const { data: { buildId } } = await getPipelinesService().triggerPipeline(projectId, pipelineId, {
                ref: `refs/heads/${branch}`
            });
            return JSON.stringify({ buildId })
        } catch (error) {
            console.error("triggerPipeline error", error)
        }
    }

    async function listAvailableVersions(app: string) {
        try {
            const { data: { versions } } = await getKubeService().getMicroserviceVersions(
                app,
                projectId,
                "0",
                "15"
            )
            if (versions) {
                return JSON.stringify(versions.tags.filter((t) => t.name !== "latest"))
            } else {
                throw Error("App versions not found!");
            }
        } catch (error) {
            console.error("error", error)
        }
    }

    async function deployVersion(app: string, version: string, environment: string) {
        try {
            // TODO hardcoded values
            await getKubeService().deployMicroservice(
                app,
                "pixie-us68E41qO6-EKS",
                "microtica",
                projectId,
                {
                    deployment: {
                        configurations: [],
                        image: version
                    }
                }
            )
            return JSON.stringify({ done: true });
        } catch (error) {
            console.error("error", error)
        }
    }

    const BranchSelect = ({ branches }: { branches: string[] }) => (
        <Card style={{ width: "80%", marginLeft: "60px" }}> 
            <Form
                onFinish={(value) => {
                    ref.current?.sendMessage(`Trigger pipeline build from branch ${value.branch}`);
                }}
            >
                <Form.Item label="Branch" name={'branch'} rules={[{ required: true, message: 'Please select branch!' }]} className='chat-form-item'>
                    <Select
                        showSearch
                        style={{ width: "calc(100% - 60px)" }}
                        placeholder="Select branch"
                    >
                        {
                            branches.map((branch: string) => (
                                <Select.Option value={branch} key={branch}>
                                    {branch}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form>
        </Card>
    );

    const VersionSelect = ({ versions }: { versions: IAppVersion[] }) => (
        <Card style={{ width: "80%", marginLeft: "60px" }}>
            <Form
                onFinish={(value) => {
                    ref.current?.sendMessage(`Deploy version ${value.version}`);
                }}
            >
                <Form.Item label="Version" name={'version'} rules={[{ required: true, message: 'Please select version!' }]} className='chat-form-item'>
                    <Select
                        style={{ width: "calc(100% - 60px)" }}
                        placeholder="Select app version"
                        className="ant-select-deploy-versions"
                    >
                        {
                            versions.map(version => (
                                <Select.Option value={version.name} key={version.name} className="flex-align-center">
                                    <div className="flex-justify-space-between">
                                        <span>
                                            <Text>
                                                {version.commit?.message}
                                            </Text>
                                        </span>
                                        <span style={{ fontSize: 12 }} className="gray-text">
                                            <ClockCircleOutlined /> {moment(version.date).fromNow()}
                                        </span>
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        <GitCommitLink
                                            version={version.name}
                                            commitType={version.commit?.type}
                                            hideIcon
                                            branch={version.commit?.name}
                                        />
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        <span style={{ color: "#000000e0" }}>
                                            <Avatar src={version.commit?.user?.avatar} style={{ width: "15px", height: "15px" }} />
                                            {version.commit?.user?.name}
                                        </span>
                                    </div>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form>
        </Card>
    );


    const availableFunctions = {
        findPipelineByRepositoryName,
        triggerPipeline,
        listAvailableVersions,
        deployVersion
    };

    const handleUserInput = async (messages: any[]) => {
        let newMessages: any[] = [];
        // user has sent his first message, insert system message first
        if (messages.length === 1) {
            newMessages.push(
                {
                    role: "system",
                    content: "You are a DevOps automation tool that can execute different operations using Microtica"
                }
            )
        }
        // keep only 'role' and 'content' props
        newMessages.push(
            {
                role: "user",
                content: messages[messages.length - 1].content
            }
        )

        let responseMessage = {} as any;
        let tollResponseMessage = {} as any;
        let customComponentMessage;
        try {
            console.log('BEFORE messages: ', messages);
            console.log("BEFORE openaiMessages: ", openaiMessages);
            const response = await openai.chat.completions.create({
                model: OPENAI.MODEL_NAME,
                messages: openaiMessages.concat(newMessages),
                tools: microticaFunctions,
                tool_choice: "auto",
                // TODO max_tokens: 4000
            });

            responseMessage = response.choices[0].message;
            console.log("responseMessage: ", responseMessage);
            newMessages.push(responseMessage);

            if (responseMessage.tool_calls) {
                for (const toolCall of responseMessage.tool_calls) {
                    let functionResponse;
                    const functionName = toolCall.function.name as 'findPipelineByRepositoryName' | 'triggerPipeline' | 'deployVersion' | 'listAvailableVersions';
                    const functionArgs = JSON.parse(toolCall.function.arguments);

                    if (functionName === 'findPipelineByRepositoryName') {
                        functionResponse = await availableFunctions[functionName](functionArgs.repositoryName);
                        customComponentMessage = {
                            role: "custom",
                            id: "available-branches",
                            content: functionResponse!
                        };
                    } else if (functionName === 'triggerPipeline') {
                        functionResponse = await availableFunctions[functionName](
                            functionArgs.pipelineId,
                            functionArgs.branch
                        );
                    } else if (functionName === 'listAvailableVersions') {
                        functionResponse = await availableFunctions[functionName](
                            functionArgs.app
                        );
                        customComponentMessage = {
                            role: "custom",
                            id: "available-app-versions",
                            content: functionResponse!
                        };
                    } else if (functionName === 'deployVersion') {
                        functionResponse = await availableFunctions[functionName](
                            functionArgs.app,
                            functionArgs.version,
                            functionArgs.environment
                        );
                    }

                    console.log("responseMessage tool call: ", functionName, {
                        tool_call_id: toolCall.id,
                        role: "tool",
                        name: functionName,
                        content: functionResponse,
                    })

                    newMessages.push({
                        tool_call_id: toolCall.id,
                        role: "tool",
                        name: functionName,
                        content: functionResponse,
                    });
                }
                const tollResponse = await openai.chat.completions.create({
                    model: OPENAI.MODEL_NAME,
                    messages: openaiMessages.concat(newMessages),
                    tools: microticaFunctions,
                    tool_choice: "auto",
                    // TODO max_tokens: 4000
                });
                tollResponseMessage = tollResponse.choices[0].message;
                console.log("tollResponseMessage: ", tollResponseMessage);
                newMessages.push(tollResponseMessage);
                if (customComponentMessage) {
                    console.log("!! pushing custom message");
                    ref.current?.pushChat(customComponentMessage);
                }
            }
        } catch (e) {
            console.log('error: ', e);
            return new Response("Something is going wrong, Please try again.");
        } finally {
            console.log('END concat: ', openaiMessages.concat(newMessages));
            console.log('END messages: ', messages);

            setOpenaiMessages(openaiMessages.concat(newMessages));
            if (tollResponseMessage || responseMessage) {
                return new Response(tollResponseMessage.content || responseMessage.content);
            }
        }
    }
    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader
                    title={
                        <>
                            Chat
                        </>
                    }
                />
            }
        >
            <div className="chat">
                <ProChat
                    chatRef={ref}
                    helloMessage={'Hi! How can I help you?'}
                    request={async (messages: any[]) => await handleUserInput(messages)}
                    chatItemRenderConfig={{
                        render: (item, _dom, defaultDom) => {
                            if (item?.originData?.role === 'custom') {
                                if (item.originData.id === 'available-branches') {
                                    return <BranchSelect {...JSON.parse(item?.originData?.content)} />
                                }
                                if (item.originData.id === 'available-app-versions') {
                                    return <VersionSelect versions={JSON.parse(item?.originData?.content)} />
                                }
                            }
                            return defaultDom;
                        },
                        contentRender: (props, defaultDom) => {
                            if (props?.originData?.role === "user") {
                                return <div className='chat-primary-color-bg'>
                                    {defaultDom}
                                </div>
                            }
                            return defaultDom;
                        },
                        actionsRender: false, // hide Copy, Regenerate, Delete, Edit actions for message
                        avatarRender: (props, _defaultDom) => {
                            const role = (props.messageExtra as IMessageExtraType).props?.data?.role || "user";
                            // Microtica assistant
                            if (props.messageExtra && role === "user") {
                                return <Avatar icon={<UserOutlined />} style={{ marginTop: "5px" }} />
                            }
                            return <Badge
                                count={'🤖'}
                                style={{
                                    backgroundColor: "#fff",
                                    padding: 0,
                                    height: "16px",
                                    minWidth: "fit-content",
                                    alignItems: "center",
                                    transform: "translate(40%, -45%)",
                                    fontSize: "16px",
                                    marginTop: "2px"
                                }}
                            >
                                <img src={MicroticaLogo} style={{ height: "32px", marginTop: "5px" }} alt="microtica" />
                            </Badge>
                        },
                    }}
                    locale='en-US'
                />
            </div>
        </PageContentWrapper>
    );
}
*/
export default ProjectChat;