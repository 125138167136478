import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Divider, notification, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { getCloudService, getEnvironmentService, getMonitoringService } from "../../backend";
import { trackQuickDeploy } from "../../backend/tracking/deployment";
import { trackEnvComponentRemoved } from "../../backend/tracking/environment";
import ChooseAwsAccountModal from "../../components/modals/ChooseAwsAccountModal";
import ConnectAwsAccountModal from "../../components/settings/ConnectAwsAccountModal";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { IComponentDetails, IEnvDetails, ResourceConfigurationAndStatus } from "../../types";
import { linkAwsAccountToStage } from "../../utils/aws-accounts";
import { isResourceActionEnabled } from "../../utils/environment";
import { getResourcesReferencingParticularResource } from "../../utils/environment/get-resource-references";
import { newComponentState } from "../../recoil/environment";
import { useSetRecoilState } from "recoil";
import ConnectGcpAccountModal from "../../components/settings/ConnectGcpAccountModal";
import ChooseGcpAccountModal from "../../components/modals/ChooseGcpAccountModal";

export default function ResourceSettingsDangerZone() {
    const { projectId, envId } = useParams() as { projectId: string; envId: string; };
    const navigate = useNavigate();
    const { env, component } = useOutletContext<{ env: IEnvDetails, component: IComponentDetails }>();
    const setNewComponent = useSetRecoilState(newComponentState);
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const [connectAwsAccountModalVisible, setConnectAwsAccountModalVisible] = useState(false);
    const [chooseAwsAccountModal, setChooseAwsAccountModal] = useState(false);
    const [cloudAccountId, setCloudAccountId] = useState<string>();
    const [executeSavedAction, setExecuteSavedAction] = useState<Function>();
    const [envStatus, setEnvStatus] = useState<string>();
    const [undeployInfoMessage, setUndeployInfoMessage] = useState<string>();
    const [deleteInfoMessage, setDeleteInfoMessage] = useState<string>();
    const [referencedByResources, setReferencedByResources] = useState<string[]>();
    const [connectGcpAccountModalVisible, setConnectGcpAccountModalVisible] = useState(false);
    const [chooseGcpAccountModal, setChooseGcpAccountModal] = useState(false);

    useEffect(() => {
        fetchEnv();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [env, component.status])

    const fetchEnv = async () => {
        const mappedResourcesConfigurations = env.resources
            .filter(r => !["NOT_DEPLOYED", "DELETE_COMPLETE", "ROLLBACK_COMPLETE"].includes(r.status))
            .reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr.name]: {
                        status: curr.status,
                        configurations: curr.configurations
                    }
                }
            }, {} as ResourceConfigurationAndStatus);

        // check if there are other resources that reference the one that is selected for undeployment
        const referencedByResources = getResourcesReferencingParticularResource(component.name, mappedResourcesConfigurations!);

        setReferencedByResources(referencedByResources);
        const newEnvStatus = env.status;
        setEnvStatus(newEnvStatus);
        if (newEnvStatus?.includes("IN_PROGRESS") && newEnvStatus !== "REVIEW_IN_PROGRESS") {
            setDeleteInfoMessage("Component actions are disabled while Environment deployment is in progress. Please wait for the deployment to finish before taking any actions.");
            setUndeployInfoMessage("Component actions are disabled while Environment deployment is in progress. Please wait for the deployment to finish before taking any actions.");
        } else if (!["DELETE_COMPLETE", "NOT_DEPLOYED", "ROLLBACK_COMPLETE"].includes(component.status)) {
            setDeleteInfoMessage("This component is active and can not be deleted. Please undeploy it first.")
        }
    }

    const undeployResource = async (resourceName: string) => {
        try {
            if (
                (env.cloudProvider === "aws" && env.awsAccountId && env.region) ||
                (env.cloudProvider === "google" && env.gcpProjectId && env.gcpRegion && env.gcpZone)
            ) {
                // Disable cluster monitoring
                try {
                    await getMonitoringService().disableMonitoring(
                        resourceName,
                        envId,
                        projectId
                    );
                } catch (error) {
                    // ignore error
                }

                await getEnvironmentService().deployStage(
                    envId,
                    projectId,
                    {
                        partial: true,
                        resourceVersionOverrides: {
                            [resourceName]: null as any
                        }
                    }
                );
                notification.success({
                    message: "Undeploy in Progress",
                    description: <>
                        It could take a few moments for the changes to take effect.
                        <div>
                            <a href={`/projects/${projectId}/pipelines`}>View in Pipelines</a>
                        </div>
                    </>,
                    icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
                });
                trackQuickDeploy(envId);
            } else {
                if (env.cloudProvider === "aws") {
                    const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(projectId);
                    if (!!awsAccounts.length) {
                        // project has AWS accounts, but none of them is connected to the stage
                        setChooseAwsAccountModal(true);
                    } else {
                        // there is no AWS account on the project
                        setConnectAwsAccountModalVisible(true);
                    }
                } else if (env.cloudProvider === "google") {
                    const { data: { gcpAccounts } } = await getCloudService().getGcpAccounts(projectId);
                    if (!!gcpAccounts.length) {
                        // project has GCP accounts, but none of them is connected to the stage
                        setChooseGcpAccountModal(true);
                    } else {
                        // there is no GCP account on the project
                        setConnectGcpAccountModalVisible(true);
                    }
                }
            }
        } catch (error: any) {
            notification.error({
                message: "Error while trying to undeploy component",
                description: error?.response?.data?.message ? error?.response?.data?.message : "We encountered an error while trying to undeploy this component. Please try again."
            });
        }
    }
    const showUndeployConfirm = (resourceName: string) => {
        openConfirmModal({
            title: "Undeploy component",
            description:
                <Space direction="vertical">
                    <div>
                        This action will deprovision the infrastructure assiciated with this component from your cloud account.
                    </div>
                    <div>
                        However, the component will remain in the environment infrastructure. You can redeploy it at any time.
                    </div>
                </Space>
            ,
            confirmation: resourceName,
            okText: "Undeploy",
            cancelText: "Cancel",
            onOk: () => { return undeployResource(resourceName); }
        })
    }

    const deleteResource = async (resourceName: string) => {
        try {
            // delete resource
            await getEnvironmentService().deleteResource(envId!, resourceName, projectId!);
            setNewComponent({ projectId, envId, name: resourceName });
            notification.success({
                message: "Component deleted",
                description: `The component ${resourceName} has been successfully deleted from this environment.`
            });
            trackEnvComponentRemoved(
                envId!,
                component.component.id,
                component.component.name,
            );
            setTimeout(() => {
                navigate(`/projects/${projectId}/environments/${envId}`);
            }, 1000);
        } catch (error: any) {
            notification.error({
                message: "Error while trying to delete component",
                description: error?.response?.data?.message ? error?.response?.data?.message : "We encountered an error while trying to delete this component. Please try again."
            });
        }
    }

    const showDeleteConfirm = (resourceName: string) => {
        openConfirmModal({
            title: "Delete component",
            description:
                <Space direction="vertical">
                    <div>
                        The component will be permanently deleted from this environment.
                    </div>
                    <div>
                        If you just want to undeploy it, choose <span style={{ fontStyle: 'italic' }}>Undeploy</span>.
                    </div>
                    <Alert message={<b>This action cannot be undone.</b>} type="error" />
                </Space>,
            confirmation: component.name,
            okText: "Delete Component",
            cancelText: "Cancel",
            onOk: () => { return deleteResource(resourceName); }
        })
    }

    return (
        <>
            <Space direction="vertical" className="full-width">
                <Card bordered>
                    <Card.Meta
                        title={
                            <Space>
                                <ExclamationCircleOutlined style={{ color: 'red' }} />
                                Undeploy Component
                            </Space>
                        }
                        description="This action will remove the infrastructure assiciated with this component from your cloud account."
                    />
                    {
                        !!referencedByResources?.length &&
                        <Space direction="vertical">
                            <br />
                            <Alert
                                message={
                                    <div>
                                        The <b>{component.name}</b> component is being referenced by some active components. If you proceed to <b>Undeploy</b> this component, the following components will also be undeployed:
                                        <b> {referencedByResources.join(", ")}.</b>
                                    </div>
                                }
                                showIcon
                                type="warning"
                            />
                        </Space>
                    }
                    <Divider />

                    {
                        undeployInfoMessage &&
                        <div>
                            <Alert
                                type="info"
                                description={undeployInfoMessage}
                            />
                            <br />
                        </div>
                    }
                    <div className="text-right">
                        <Button
                            danger
                            disabled={!isResourceActionEnabled({
                                action: "undeploy",
                                resourceStatus: component.status,
                                envStatus: envStatus!
                            })}
                            onClick={() => showUndeployConfirm(component.name)}
                        >
                            Undeploy
                        </Button>
                    </div>
                </Card>
                <br />
                <Card bordered>
                    <Card.Meta
                        title={
                            <Space>
                                <ExclamationCircleOutlined style={{ color: 'red' }} />
                                Delete Component
                            </Space>
                        }
                        description="The component will be permanently deleted, including its deployments. This action is irreversible and can not be undone."
                    />
                    <Divider />

                    {
                        deleteInfoMessage &&
                        <div>
                            <Alert
                                type="info"
                                description={deleteInfoMessage}
                            />
                            <br />
                        </div>
                    }
                    <div className="text-right">
                        <Button
                            type="primary"
                            danger
                            disabled={!isResourceActionEnabled({
                                action: "delete",
                                resourceStatus: component.status,
                                envStatus: envStatus!
                            })}
                            onClick={() => showDeleteConfirm(component.name)}
                        >
                            Delete
                        </Button>
                    </div>
                </Card>
            </Space>

            {/* AWS account */}
            {
                // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'connectAwsAccountModalVisible'
                // We have a process of periodic API calls which remain active even if when the modal closes
                connectAwsAccountModalVisible &&
                <ConnectAwsAccountModal
                    visible={connectAwsAccountModalVisible}
                    onOk={async (newAwsAccountId) => {
                        setConnectAwsAccountModalVisible(false);
                        setCloudAccountId(newAwsAccountId);
                        setChooseAwsAccountModal(true);
                    }}
                    onCancel={() => setConnectAwsAccountModalVisible(false)}
                />
            }
            {
                // This modal becomes active when the user connects an AWS account and now needs to select a region for the stage
                chooseAwsAccountModal &&
                <ChooseAwsAccountModal
                    selectedAccount={{ accountId: cloudAccountId, region: undefined }}
                    visible={chooseAwsAccountModal}
                    onCancel={() => setChooseAwsAccountModal(false)}
                    onOk={async ({ accountId, region }: { accountId: string, region: string }) => {
                        await linkAwsAccountToStage({
                            envId: envId,
                            projectId: projectId,
                            awsAccountId: accountId,
                            awsRegion: region
                        });
                        setChooseAwsAccountModal(false);
                        executeSavedAction?.();
                    }}
                />
            }

            {/* GCP account */}
            {
                connectGcpAccountModalVisible &&
                <ConnectGcpAccountModal
                    visible={connectGcpAccountModalVisible}
                    onOk={async (newAccountId) => {
                        setConnectGcpAccountModalVisible(false);
                        setCloudAccountId(newAccountId);
                        setChooseGcpAccountModal(true);
                    }}
                    onCancel={() => setConnectGcpAccountModalVisible(false)}
                />
            }
            {
                // This modal becomes active when the user connects an GCP account and now needs to select a region for the stage
                chooseGcpAccountModal &&
                <ChooseGcpAccountModal
                    env={env}
                    visible={chooseGcpAccountModal}
                    onCancel={() => setChooseGcpAccountModal(false)}
                    onOk={() => {
                        setChooseGcpAccountModal(false);
                        executeSavedAction?.();
                    }}
                />
            }
        </>
    );
}